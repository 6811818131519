import React from 'react'
import Slide from './Slide';
import { sliders } from '../common/Constants'

const SliderMainVideo = () => {
    return (
        <div className="main-slider style-two default-banner" id="home">
			<div className="tp-banner-container">
				<div className="tp-banner" >
					<div id="welcome_wrapper" className="rev_slider_wrapper fullscreen-container" data-alias="reveal-add-on36" data-source="gallery" style={{ background:'#ffffff', padding:'0px' }}>
						<div id="welcome" className="rev_slider fullscreenbanner" style={{ display:'none'} } data-version="5.4.7.2">
                            <ul>
                                {
                                    sliders.filter(item => item.display).map((slide,i) => {
                                        return(
                                            <li key={ i }
                                            data-transition='slideoververtical'
                                            data-slotamount='default'
                                            data-hideafterloop='0'
                                            data-hideslideonmobile="off"
                                            data-easein='default'
                                            data-easeout='default'
                                            data-masterspeed='default'
                                            data-thumb=''
                                            data-rotate='0'
                                            data-saveperformance='off'
                                            data-title='Slide'
                                            data-param1={ slide.params[0] }
                                            data-param2={ slide.params[1] }
                                            data-param3={ slide.params[2] }
                                            data-param4={ slide.params[3] }
                                            data-param5={ slide.params[4] }
                                            data-param6={ slide.params[5] }
                                            data-param7={ slide.params[6] }
                                            data-param8={ slide.params[7] }
                                            data-param9={ slide.params[8] }
                                            data-param10={ slide.params[9] }
                                            data-description={ slide.params.description }>
                                                <img src={ 'images/slider/dummy.png' }  alt=""  data-lazyload={ 'images/slider/' + slide.imgName + '.jpg' } data-bgposition="center center" data-kenburns="on" data-duration="4000" data-ease="Power3.easeInOut" data-scalestart="150" data-scaleend="100" data-rotatestart="0" data-rotateend="0" data-blurstart="0" data-blurend="0" data-offsetstart="-100 0" data-offsetend="-100 0" data-bgparallax="4" className="rev-slidebg" data-no-retina/>
                                                <Slide slide={ slide } ></Slide>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            <div className='tp-bannertimer tp-bottom' style={{ visibility: 'hidden !important' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SliderMainVideo
