/* <!------- CONTACT INFORMATION ------> */
exports.contactInformation = {
    email: 'bmendiola@svecnc.com',
    phone: '(336) 343-6823	',
    cel: '',
    address: '1031 E Mountain St.',
    addressPart1: 'Kernersville, NC 27284 ',
    addressPart2: 'NC 27284',
    schedule: '08:00 - 17:00'
}

/* <!---------- NAVS - MENU - ROUTER ----------> */
exports.navs = [{
        name: 'Home',
        display: true,
        items: [],
        displayItems: false,
        path: '/',
        active: true
    }, {
        name: 'About',
        display: true, // mostrar opcion en menu principal
        topBar: true, // mostrar la opcion en menu superior
        items: [], // array para crear submenu
        displayItems: false, // mostrar/ocultar  items de menu.
        path: '/about', // ruta que apunta este elemento del menu
        active: false // agregar por de defecto false excepto Home.
    },
    {
        name: 'Services',
        display: true,
        items: [{
                name: 'Low Voltage Systems',
                display: true,
                isSubMenu: true, // para que se convierta en un submenu.
                topBar: true,
                items: [{
                    name: 'Access Control',
                    display: true,
                    items: [],
                    footerMenu: true, // para mostrar esta opcion del menu en el foter de la pagina.
                    displayItems: false,
                    topBar: true,
                    path: '/access-control'
                }, {
                    name: 'Fiber Optics',
                    display: true,
                    items: [],
                    footerMenu: true,
                    displayItems: false,
                    topBar: true,
                    path: '/fiber-optic'
                }, {
                    name: 'Fire Alarm',
                    display: true,
                    items: [],
                    footerMenu: true,
                    displayItems: false,
                    topBar: true,
                    path: '/fire-alarm'
                }, {
                    name: 'Instrumentation and Start Up',
                    display: true,
                    items: [],
                    displayItems: false,
                    topBar: true,
                    path: '/instrumentation-and-start-up',
                    keyAccordion: 0
                }, {
                    name: 'Structure Cabling',
                    display: true,
                    leftMenu: true,
                    items: [],
                    displayItems: false,
                    path: '/structure-cabling',
                    keyAccordion: 10
                }, {
                    name: 'Telecommunications',
                    display: true,
                    leftMenu: true,
                    items: [],
                    displayItems: false,
                    path: '/telecommunications',
                    keyAccordion: 11
                }, {
                    name: 'Testing Services',
                    display: true,
                    leftMenu: true,
                    items: [],
                    displayItems: false,
                    path: '/testing-services',
                    keyAccordion: 12
                }],
                displayItems: true,
                path: '/low-voltage-systems'
            }, {
                name: 'Commercial Electrical',
                display: true,
                isSubMenu: false,
                items: [],
                displayItems: false,
                path: '/comercial-electrical'
            }, {
                name: 'Mechanical',
                display: true,
                items: [],
                displayItems: false,
                path: '/mechanical',
                keyAccordion: 5
            }, {
                name: 'Multifamily Electrical',
                display: true,
                items: [],
                displayItems: false,
                path: '/multifamily-electrical',
                keyAccordion: 5
            }, {
                name: 'Project Coordination',
                display: true,
                items: [],
                displayItems: false,
                path: '/project-coordination',
                keyAccordion: 5
            }
        ],
        displayItems: true,
        path: '/services',
        active: false
    }, {
        name: 'Commecial',
        display: true,
        topBar: true,
        items: [{
            name: 'Breaker Box Wiring',
            display: true,
            leftMenu: true,
            items: [],
            displayItems: false,
            path: '/breaker-box-wiring',
            keyAccordion: 1
        },{
            name:'Data Wiring & Networking',
            display: true,
            leftMenu: true,
            items: [],
            displayItems: false,
            path: '/data-wiring-networking',
            keyAccordion: 1
        },{
            name:'Electrical Outlet Instalation',
            display: true,
            leftMenu: true,
            items: [],
            displayItems: false,
            path: '/electrical-outlet-instalation',
            keyAccordion: 1
        },{
            name:'Electrical Inspections',
            display: true,
            leftMenu: true,
            items: [],
            displayItems: false,
            path: '/electrical-inspections',
            keyAccordion: 1
        },{
            name:'Electrical Wiring',
            display: true,
            leftMenu: true,
            items: [],
            displayItems: false,
            path: '/electrical-wiring',
            keyAccordion: 1
        },{
            name:'GFCI Instalation',
            display: true,
            leftMenu: true,
            items: [],
            displayItems: false,
            path: '/gfci-instalation',
            keyAccordion: 1
        },{
            name:'Office Wiring Solutions',
            display: true,
            leftMenu: true,
            items: [],
            displayItems: false,
            path: '/office-wiring-solutions',
            keyAccordion: 1
        },{
            name:'Outdoor Lighting',
            display: true,
            leftMenu: true,
            items: [],
            displayItems: false,
            path: '/outdoor-lighting',
            keyAccordion: 1
        },{
            name:'Phone Wiring & Networking',
            display: true,
            leftMenu: true,
            items: [],
            displayItems: false,
            path: '/phone-wiring-networking',
            keyAccordion: 1
        },{
            name:'Power and Lighting Desing',
            display: true,
            leftMenu: true,
            items: [],
            displayItems: false,
            path: '/power-lighting-desing',
            keyAccordion: 1
        }],
        displayItems: true,
        path: '/comercial',
        active: false
    }, {
        name: 'Industrial',
        display: true,
        topBar: true,
        items: [
            {
                name:'Lighting Retrofit & Soluntions',
                display: true,
                leftMenu: true,
                items: [],
                displayItems: false,
                path: '/lighting-retrofit-solutions',
                keyAccordion: 1
            },{
                name:'Energy Efficiency Solutions',
                display: true,
                leftMenu: true,
                items: [],
                displayItems: false,
                path: '/energy-efficiency-solutions',
                keyAccordion: 1
            },{
                name:'PLC Services',
                display: true,
                leftMenu: true,
                items: [],
                displayItems: false,
                path: '/plc-services',
                keyAccordion: 1
            },{
                name:'Harmonic Mitigations',
                display: true,
                leftMenu: true,
                items: [],
                displayItems: false,
                path: '/harmonic-mitigations',
                keyAccordion: 1
            },{
                name:'Standby Generator System',
                display: true,
                leftMenu: true,
                items: [],
                displayItems: false,
                path: '/standby-generator-system',
                keyAccordion: 1
            },{
                name:'Carousel & Conveyor Wiring',
                display: true,
                leftMenu: true,
                items: [],
                displayItems: false,
                path: '/carousel-conveyor-Wiring',
                keyAccordion: 1
            },{
                name:'Speciality Voltages',
                display: true,
                leftMenu: true,
                items: [],
                displayItems: false,
                path: '/speciality-voltages',
                keyAccordion: 1
            }
        ],
        displayItems: true,
        path: '/industrial',
        active: false
    }, {
        name: 'Consulting',
        display: true,
        topBar: true,
        items: [],
        displayItems: false,
        path: '/electrical-consulting',
        active: false
    }, {
        name: 'Locations',
        display: true,
        topBar: true,
        items: [],
        displayItems: false,
        path: '/local-services-area',
        active: false
    }, {
        name: 'Contact',
        display: true,
        items: [],
        displayItems: false,
        path: '/contact',
        active: false
    },{
        name: 'Get a Quote',
        display: false,
        items: [],
        displayItems: false,
        path: '/get-a-quote',
        active: false
    }
]
exports.navIndustry = {
    name: 'Industries',
    display: true,
    isSubMenu: true,
    topBar: true,
    items: [{
        name: 'Power Generation',
        display: true,
        items: [],
        displayItems: false,
        topBar: true,
        path: '/power-generation'
    }, {
        name: 'Industrial Construction',
        display: true,
        items: [],
        displayItems: false,
        topBar: true,
        path: '/underConstructionPage'
    }, {
        name: 'Insulation',
        display: true,
        items: [],
        displayItems: false,
        topBar: true,
        path: '/underConstructionPage'
    }, {
        name: 'Fabrication',
        display: true,
        isSubMenu: true,
        items: [],
        displayItems: false,
        topBar: true,
        path: '/underConstructionPage'
    }, {
        name: 'Underground Construction',
        display: false,
        items: [],
        displayItems: false,
        topBar: true,
        path: '/underConstructionPage',
        keyAccordion: 1
    }, {
        name: 'Refining Industry',
        display: true,
        leftMenu: true,
        items: [],
        displayItems: false,
        topBar: true,
        path: '/underConstructionPage',
        keyAccordion: 14
    }],
    displayItems: true,
    path: '/underConstructionPage'
}

/* <!-------  SLIDER  ------> */
exports.sliders = [
    // {
    //     index: '900',
    //     imgName: 'video',
    //     typeSlide: 'Slide Title',
    //     params: ['','','','','','','','','',''],
    //     description: '',
    //     topTitle: 'Electrical Contractors',
    //     title: 'Get a quality',
    //     titlePart2: 'service',
    //     subTitle: 'Surpassing Expectations',
    //     type: 'video',
    //     format: 'mp4',
    //     display: true
    // },
    {
        index: '901',
        imgName: 'slide09',
        typeSlide: 'Slide',
        params: ['', '', '', '', '', '', '', '', '', ''],
        description: '',
        topTitle: 'Electrical',
        title: 'COMMERCIAL AND',
        titlePart2: 'INDUSTRIAL',
        subTitle: 'Surpassing Expectations',
        content: 'SV Electrical Contractors is a full-service electrical contractor providing construction services for commercial & industrial clients throughout North and South Carolina',
        type: 'img',
        format: 'jpg',
        display: true
    }, {
        index: '902',
        imgName: 'slide10',
        typeSlide: 'Slide',
        params: ['', '', '', '', '', '', '', '', '', ''],
        description: '',
        topTitle: 'SV Electrical Contractors',
        titlePart2: 'Mentality',
        content: 'Electrical safety inspections are an important requirement for property owners. Since this is a top priority, routine safety inspections are essential.',
        type: 'img',
        format: 'jpg',
        display: true
    }, {
        index: '903',
        imgName: 'slide11',
        typeSlide: 'Slide',
        params: ['', '', '', '', '', '', '', '', '', ''],
        description: '',
        topTitle: 'SV Electrical Contractors',
        titlePart2: 'Experience',
        content: 'Our skilled technicians work on new construction projects, turnarounds, installations, repairs and inspections. ',
        type: 'img',
        format: 'jpg',
        display: true
    }, {
        index: '903',
        imgName: 'slide12',
        typeSlide: 'Slide',
        params: ['', '', '', '', '', '', '', '', '', ''],
        description: '',
        topTitle: 'SV Electrical Contractors',
        titlePart2: 'Commercial',
        content: 'We work on power and lighting design, lighting retrofits, data networking installation, breaker box wiring, and all types of commercial electric projects.',
        type: 'img',
        format: 'jpg',
        display: true
    }
]

/* <!------- CARROUSEL ------> */
exports.carrouselProjects = [{
    title: 'Power Generation',
    description: 'SV Electrical Contractors, provides high specification fabrication services for many power plant components like Boilers, Piping, Ducting and Turbines.',
    pathImg: 'images/capabilities/industrial/electricPower.jpg',
    path: '/power-generation',
    icon: 'flaticon-worker'
}, {
    title: 'Pipe Fabrication',
    description: 'This is one of our strengths. We produce more than six thousand pipe spools every month. We also fabricate more than ten carbon and alloy piping systems.',
    pathImg: 'images/capabilities/fabrication/Pipeline.jpg',
    path: '/pipe-fabrication',
    icon: 'flaticon-settings'
}, {
    title: 'Industrial Insulation',
    description: 'Our crew of craftsmen can perform over 25 special insulation activities to the highest specifications to ensure the excellence of all and each one of them.',
    pathImg: 'images/capabilities/industrial/industrialInsulationHome.jpg',
    path: '/industrial-insulation',
    icon: 'flaticon-engineer-1'
}, {
    title: 'Tower & Vessels',
    description: 'For towers and vessels we have 6 tipes of services, which we can adapt to your specific needs: Retiring entire towers, fabrication of vessels, general repairs, installation,  upgrades and modifications.',
    pathImg: 'images/capabilities/fabrication/TowerAndVessel.jpg',
    path: '/tower-And-Vessels',
    icon: 'flaticon-factory-1'
}, {
    title: 'Heat exchangers services',
    description: 'Without the proper installation and maintenance of heat exchangers that can manage the heating or cooling of industrial fluids and substances, the whole production process might drive to a halt.',
    pathImg: 'images/capabilities/fabrication/HeatExchangers.jpg',
    path: '/heat-exchangers-services',
    icon: 'flaticon-worker'
}, {
    title: 'Specialty Welding',
    description: 'SV Electrical Contractors provides premium specialty welding and field services. Our skilled welders have the metallurgical as well as field expertise it takes to weld on a wide range of materials.',
    pathImg: 'images/capabilities/fabrication/SpecialtyWelding.jpg',
    path: '/specialty-welding',
    icon: 'flaticon-settings'
}, {
    title: 'Project Management',
    description: 'To ensure a successful project, we focus on providing clear communication and strong leadership. In this way, we can confidently say that any possible problem or inconvenience will be either prevented.',
    pathImg: 'images/capabilities/fabrication/ProjectManagement700x500.jpg',
    path: '/power-generation',
    icon: 'flaticon-worker'
}, {
    title: 'Boiler Services',
    description: 'SV Electrical Contractors Boilers installation and maintenance services offer an all encompassing solution to any industrial installation incorporating boilers and associated piping.',
    pathImg: 'images/capabilities/fabrication/Boilers700x500.jpg',
    path: '/boiler-services',
    icon: 'flaticon-worker'
}, {
    title: 'Maintenance',
    description: 'To prevent accidents, maximize efficiency and fix problems with existing facilities we offer maintenance services with a clear communication system.',
    pathImg: 'images/capabilities/fabrication/Maintenance700x500.jpg',
    path: '/maintenance',
    icon: 'flaticon-worker'
}, {
    title: 'sheet Metal',
    description: 'We have a full spectrum in sheet metal fabrication services, that include 9 types of different products. We specialize in carbon steel, stainless, aluminum, and other common alloy material fabrications.',
    pathImg: 'images/capabilities/fabrication/SheelMetal700x500.jpg',
    path: '/full-spectrum-of-sheet-metal-services',
    icon: 'flaticon-factory-1'
}, {
    title: 'Material Processing',
    description: 'Without a minimum order required, we provide material processing fabrication services on time and for much less than the average costs, because of our use of modernized state-of-the-art equipment.',
    pathImg: 'images/capabilities/fabrication/MaterialProcessing700x500.jpg',
    path: '/material-processing',
    icon: 'flaticon-worker'
}, {
    title: 'Turnaround planning',
    description: 'We know all the losses that can come because of a bad management of the capital or of a mistake respect at the times to make a closure, among others.',
    pathImg: 'images/capabilities/fabrication/TurnaroundPlanningExecution700x500.jpg',
    path: '/turnaround-planning-and-execution',
    icon: 'flaticon-settings'
}]

exports.cardsFormHome = [{
    title: 'Access Control',
    description: 'There are many ways to protect and secure a facility and employees.',
    pathImg: 'images/gallery/access_control.jpg',
    path: '/access-control',
    icon: 'fas fa-key fa-3x',
    fade: 'fadeInLeft'
}, {
    title: 'Fire Alarm',
    description: ' Many of our customers do not want to rely on a single product...',
    pathImg: 'images/gallery/fire_alarm.jpg',
    path: '/fire-alarm',
    icon: 'fas fa-bell fa-3x',
    fade: 'fadeInRight'
}, {
    title: 'Structure Cabling',
    description: 'Structured Cabling is defined as building or campus telecommuni...',
    pathImg: 'images/gallery/structure_cabling.jpg',
    path: '/structure-cabling',
    icon: 'fas fa-ethernet fa-3x',
    fade: 'fadeInDown'
}]

exports.boxForServices = [{
    dataName: "01",
    link:"low-voltage-systems",
    icon: "fas fa-plug fa-4x",
    title: "Low Voltage Systems",
    content: "We provide low voltage systems of electricity in the most secure way to small consumers, like homes or street lighting, that only need an equally, or inferior, direct current of 1,5 volts."
}, {
    dataName: "02",
    link:"mechanical",
    icon: "fas fa-wrench fa-4x",
    title: "Mechanical Services",
    content: "For mechanical services, we have a highly innovative infrastructure, to provide you with the most advanced improvements to solve the specific problem you have, in the most complete way possible."
}, {
    dataName: "03",
    link:"comercial-electrical",
    icon: "fas fa-industry fa-4x",
    title: "Commercial Electrical",
    content: "We have a commerce dedicated to wholesale, and retail sale, of electrical materials and illumination to the home, the commerces, the industries, and others."
}, {
    dataName: "04",
    link:"multifamily-electrical",
    icon: "fas fa-compress-arrows-alt fa-4x",
    title: "Multifamily Electrical",
    content: "For buildings with a large amount of homes, we are capacitated to provide to all of them with the electrical installation they certainly need for the well living of their families."
}, {
    dataName: "05",
    link:"project-coordination",
    icon: "fas fa-tasks fa-4x",
    title: "Project Coordination",
    content: "We have experienced professionals who can manage the day-to-day operations of your project while you focus on your strategic goals. Our project managers can organize work-teams, assign them tasks and check every goal has been fulfilled as planned and on time."
}];

exports.ourServices = [{
    title: "LOW VOLTAGE SYSTEMS",
    image: "images/home/low-voltage.png",
    link: "/low-voltage-systems",
    detaItems: ['When you consider your building’s fire protection, security, and communication systems, you shouldn’t think of them as separate from another...']
}, {
    title: "Comercial Electrical",
    image: "images/home/commercial-services.png",
    link: "/comercial-electrical",
    detaItems: ['At SV Electrical Contractors we provide customized services to meet the unique requirements of your industrial or commercial facility...']
}, {
    image: "images/home/mechanical-services.png",
    link: "/mechanical",
    title: "Mechanical",
    detaItems: ['With expertise in new construction, build outs and renovations, the dedicated team at SV Electrical Contractors, Inc. is able to efficiently...']
}, {
    image: "images/home/commercial-services2.png",
    link: "/standby-generator-system",
    title: "standby generator system",
    detaItems: ['Standby generator installation is a necessity for industrial businesses. Due to the unpredictability of storms and disasters, a back-up power source is important for... ']
}, {
    image: "images/home/industrial-services.png",
    link: "/industrial",
    title: "Industrial",
    detaItems: ['As industrial and manufacturing specialists, SV Electrical Contractors has the extensive capabilities to handle your installs and upgrades well as maintenance and repairs....']
}, {
    image: "images/home/project-coordination.png",
    link: "/project-coordination",
    title: "Project Coordination",
    detaItems: ['Rely on expert project management services from SV Electrical Contractors, Inc. to complete your electrical and mechanical work on time and within budget...']
}];

exports.socialNetworks = [{
    classNameButton: 'facebook',
    classNameIcon: 'fab fa-facebook-f',
    path: 'https://www.facebook.com/',
    active: true
}, {
    classNameButton: 'instagram',
    classNameIcon: 'fab fa-instagram',
    path: 'https://www.instagram.com/',
    active: true
}, {
    classNameButton: 'google-plus',
    classNameIcon: 'fas fa-google-plus',
    path: '',
    active: false
}, {
    classNameButton: 'linkedin',
    classNameIcon: 'fas fa-linkedin',
    path: '',
    active: false
}, {
    classNameButton: 'twitter',
    classNameIcon: 'fas fa-twitter',
    path: '',
    active: false
}]

exports.companies = [{
    icon: 'flaticon-fuel-station',
    companyLogo: 'images/companies-logos/logo TSS.png',
    companyLogoWhite: 'images/companies-logos/logo TSS white.png',
    name: 'TWS',
    topTitle: 'Electrical Contractor',
    titlePart1: 'Amazing things',
    titlePart2: 'happen to your',
    titleColor: 'Business',
    subTitle: 'But high-quality companies aren´t just lucky, they make their own luck.',
    content: 'Electrical Contractor is an industry committed to the service of precise projects; We are committed to taking our people home every night requiring this to be prepared and trained in world class security, leading us to be better every day, every project.',
    contentHome: 'Electrical Contractor Workforce Solutions is a full service residential and commercial electrical contractor operating in North Carolina, Texas and Louisiana. TWS Electric brings you quality electrical work at reasonable prices.',
    buttonText: 'Read More',
    buttonPath: '',
    img: 'images/about/electricalContractor.jpg'
}, {
    icon: 'flaticon-robot-arm',
    companyLogo: 'images/companies-logos/logo nextgen.png',
    companyLogoWhite: 'images/companies-logos/logo nextgen white.png',
    name: 'NextGen',
    topTitle: 'NextGen',
    titlePart1: 'We are Industrial',
    titlePart2: 'Communications & Construction',
    titleColor: 'solutions',
    subTitle: 'We aim to be the premier global provider of added-value optical fiber based solutions.',
    content: 'As the excitement about fiber optic cables in residential and business applications continues to grow, it is easy to forget that this is not an entirely new development in communication technology.',
    contentHome: 'NextGen Communications Construction, operating in Houston, Texas, we offer a wide variety of underground and aerial fiber optic services for communities all along the eastern seaboard.',
    buttonText: 'Read More',
    buttonPath: 'http://nextgenncc.com',
    img: 'images/about/nextgen.jpg'
}, {
    icon: 'flaticon-factory-1',
    companyLogo: 'images/companies-logos/logo galpa.png',
    companyLogoWhite: 'images/companies-logos/logo galpa white.png',
    name: 'Galpa',
    topTitle: 'Galpa',
    titlePart1: 'Do It Safe and Do ',
    titlePart2: 'It Right the',
    titleColor: 'First Time',
    subTitle: 'We provide a wide range of marine repair and services.',
    content: 'We offer a single vendor and contractor solution to all your industrial and marine needs – including on site construction, pipe and steel fabrication, marine repair, construction management, metal fabrication and modules – in the United States and around the world.',
    contentHome: 'GALPA Construction LLC - Shipyard Services, provides a full range of shipyard services, including ship maintenance, repairs and refits for barges, tugboats, commercial marine.',
    buttonText: 'Read More',
    buttonPath: '/',
    img: 'images/about/galpa.jpg'
}]

exports.Users = [{
    name: 'admin',
    pass: 'admin'
}, {
    name: 'user1',
    pass: 'tss2020'
}]

/* <!------- TAGS HEAD ------> */
exports.tagsHead = {
    title: 'Electrical Contractors',
    common: {
        metaTag: [{
            name: 'robots',
            content: 'robots.txt'
        }, {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1.'
        }],
        socialMedia: []
    },
    home: {
        metaTag: [{
            name: 'description',
            content: 'Electrical Contractors'
        }, {
            name: 'keywords',
            content: 'Electrical Contractors, North Caroline, Houston'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Electrical Contractors'
        }]
    },
    about: {
        metaTag: [{
            name: 'description',
            content: 'Fiber Optic, Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }, {
            name: 'keywords',
            content: 'Qualify, Always, employees, customers, contractors, prevention, plan, execute, work, forefront'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Safety Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }]
    },
    servicesArea: {
        metaTag: [{
            name: 'Services Area',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Services Area'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    services: {
        metaTag: [{
            name: 'Services',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    comercialElectricalServices: {
        metaTag: [{
            name: 'Comercial Electrical Services',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Comercial Electrical Services'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    industrialServices: {
        metaTag: [{
            name: 'Industrial Services',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Industrial Services'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    multifamilyElectricalServices: {
        metaTag: [{
            name: 'Multifamily Electrical Services',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Multifamily Electrical Services'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    projectCoordination: {
        metaTag: [{
            name: 'Project Coordination',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Project Coordination'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    mechanicalServices: {
        metaTag: [{
            name: 'Mechanical Services',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Mechanical Services'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    comercial: {
        metaTag: [{
            name: 'Comercial',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Comercial Services'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    lowVoltageSystems: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    accessControl: {
        metaTag: [{
            name: 'description',
            content: 'Access Control, Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }, {
            name: 'keywords',
            content: 'Qualify, Always, employees, customers, contractors, prevention, plan, execute, work, forefront'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Safety Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }]
    },
    fiberOptic: {
        metaTag: [{
            name: 'description',
            content: 'Fiber Optic, Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }, {
            name: 'keywords',
            content: 'Qualify, Always, employees, customers, contractors, prevention, plan, execute, work, forefront'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Safety Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }]
    },
    fireAlarm: {
        metaTag: [{
            name: 'description',
            content: 'Fiber Optic, Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }, {
            name: 'keywords',
            content: 'Qualify, Always, employees, customers, contractors, prevention, plan, execute, work, forefront'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Safety Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }]
    },
    instrumentationAndStartUp: {
        metaTag: [{
            name: 'description',
            content: 'Fire Alarm, Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }, {
            name: 'keywords',
            content: 'Qualify, Always, employees, customers, contractors, prevention, plan, execute, work, forefront'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Safety Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }]
    },
    structureCabling: {
        metaTag: [{
            name: 'description',
            content: 'Security, Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }, {
            name: 'keywords',
            content: 'Qualify, Always, employees, customers, contractors, prevention, plan, execute, work, forefront'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Security Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }]
    },
    telecommunications: {
        metaTag: [{
            name: 'description',
            content: 'Security, Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }, {
            name: 'keywords',
            content: 'Qualify, Always, employees, customers, contractors, prevention, plan, execute, work, forefront'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Security Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }]
    },
    testingServices: {
        metaTag: [{
            name: 'description',
            content: 'Security, Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }, {
            name: 'keywords',
            content: 'Qualify, Always, employees, customers, contractors, prevention, plan, execute, work, forefront'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Security Always Electrical Contractors is committed to the health and safety of our employees, customers, contractors and the communities in which we work. It is our mission to provide and maintain a safe work environment for our family of employees'
        }]
    },
    powerGeneration: {
        metaTag: [{
            name: 'description',
            content: 'Power Generation Electrical Contractors features multiple state-of-the-art fabrication facilities located across the United States'
        }, {
            name: 'keywords',
            content: 'eléctric, generation, power, industry, Renewable Sources, Boilers, Turbines, Ducting, power, plants, services'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Electrical Contractors features multiple state-of-the-art fabrication facilities located across the United States'
        }]
    },
    electricalConsulting: {
        metaTag: [{
            name: 'description',
            content: 'Power Generation Electrical Contractors features multiple state-of-the-art fabrication facilities located across the United States'
        }, {
            name: 'keywords',
            content: 'eléctric, generation, power, industry, Renewable Sources, Boilers, Turbines, Ducting, power, plants, services'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Electrical Contractors features multiple state-of-the-art fabrication facilities located across the United States'
        }]
    },
    localServiceArea: {
        metaTag: [{
            name: 'description',
            content: 'Power Generation Electrical Contractors features multiple state-of-the-art fabrication facilities located across the United States'
        }, {
            name: 'keywords',
            content: 'eléctric, generation, power, industry, Renewable Sources, Boilers, Turbines, Ducting, power, plants, services'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Electrical Contractors features multiple state-of-the-art fabrication facilities located across the United States'
        }]
    },
    contact: {
        metaTag: [{
            name: 'description',
            content: 'Power Generation Electrical Contractors features multiple state-of-the-art fabrication facilities located across the United States'
        }, {
            name: 'keywords',
            content: 'eléctric, generation, power, industry, Renewable Sources, Boilers, Turbines, Ducting, power, plants, services'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Electrical Contractors features multiple state-of-the-art fabrication facilities located across the United States'
        }
        ]
    },
    getQuote: {
        metaTag: [{
            name: 'description',
            content: 'Power Generation Electrical Contractors features multiple state-of-the-art fabrication facilities located across the United States'
        }, {
            name: 'keywords',
            content: 'eléctric, generation, power, industry, Renewable Sources, Boilers, Turbines, Ducting, power, plants, services'
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Contractors'
        }, {
            property: 'og:description',
            content: 'Electrical Contractors features multiple state-of-the-art fabrication facilities located across the United States'
        }
        ]
    },
    brakerBoxWiring: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Braker Box Wiring'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    dataWiringNetworking: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Data Wiring Networking'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    electricalInspections: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Inspections'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    electricalOutletInstalation: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Outlet Instalation'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    electricalWiring: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Electrical Wiring'
        }, {
            property: 'og:description',
            content: ''
        }]
    },gfciInstalation: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'GFCI Instalation'
        }, {
            property: 'og:description',
            content: ''
        }]
    },officeWiringSolutions: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Office & Wiring Solutions'
        }, {
            property: 'og:description',
            content: ''
        }]
    },outdoorLighting: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Outdoor Lighting'
        }, {
            property: 'og:description',
            content: ''
        }]
    },phoneWiringNetworking: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Phone Wiring & Networking'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    powerLightingDesing: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Power Lighting Desing'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    lightingRetrofitSolutions: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Lighting Retrofit Solutions'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    energyEfficiencySolutions: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Energy Efficiency Solutions'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    plcServices: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'PLC Services'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    harmonicMitigations: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Harmonic Mitigations'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    standbyGeneratorSystem: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Standby Generator System'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    carouselConveyorWiring: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Carousel Conveyor Wiring'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
    specialityVoltages: {
        metaTag: [{
            name: 'description',
            content: ''
        }, {
            name: 'keywords',
            content: ''
        }],
        socialMedia: [{
            property: 'og:type',
            content: 'article'
        }, {
            property: 'og:title',
            content: 'Speciality Voltages'
        }, {
            property: 'og:description',
            content: ''
        }]
    },
}