import React from 'react'
import Form from './Form'
import Cards from './Cards'

const CardsAndForm = () => {
    return (
        <div className="section-full content-inner bg-white" id="about">
            <div className="container">
                <div className="row">
                    <Cards/>
                    <Form/>
                </div>
            </div>
        </div>
    )
}

export default CardsAndForm
