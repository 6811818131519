import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/litghtin_retrofit_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Lighting Retrofit" } partRed={ "Solutions" } />
                            <p>
                                For lighting retrofit solutions, we provide you with a solution that includes the whole lighting installation, from its mounting to its wiring. We know that this is the best option to upgrade your illumination system, and our objective is to carry it out satisfying your expectations in the most complete and efficient way possible.
                            </p>
                            <TitlePageBiColor partBlack={ "Lighting" } partRed={ "Audits" } />
                            <p>
                                For lighting audits, we perform a customized service of investigative processes to adapt our knowledge to your needs, requirements and desires. We know how important it is to keep the illumination atmosphere when cost-effective changes are being performed, and that's why our job is to find a balance between them.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Retrofit Lights" } partRed={ "for Efficiency" } />
                            <p>
                                To achieve the improvement of the illumination efficiency, we know that we must perform the retrofit of lights without making significant changes on the lighting of your area. We believe that there can be a balance between your ideas and ours to perform our work in the most proper and safe way.
                            </p>
                            <TitlePageBiColor partBlack={ "Lighting" } partRed={ "Controls" } />
                            <p>
                                In matters of lighting controls, we have an innovative system that will allow you to manage the illumination of your business through your own cell phone. When we finish our job, you will be able to optimize your time, to increase your productivity and to have happier and more efficient employees.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageBorder src="images/services/litghtin_retrofit_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;