import React from 'react'
import { connect } from 'react-redux'
import { contactInformation } from './Constants'

const SectionInformationFooter = props => {
    let { navSelected } = props.state
    if(navSelected !== 0) {
        return (
            <div className='site-footer style1 p-t20'>
                <div className="dlab-newsletter">
                    <div className="container">
                        <div className="ft-contact wow fadeIn" data-wow-duration="2s" data-wow-delay="0.6s" style={{ visibility: 'visible', animationDuration: '2s', animationDelay: '0.6s', animationName: 'fadeIn', background: '#D50500' }}>
                            <div className="ft-contact-bx">
                                <img src="images/icon/icon1.png" alt=""/>
                                <h4 className="title">Address</h4>
                                <p>{ contactInformation.address + contactInformation.addressPart1  }</p>
                            </div>
                            <div className="ft-contact-bx">
                                <img src="images/icon/icon2.png" alt=""/>
                                <h4 className="title">Phone</h4>
                                <p>{ contactInformation.phone }</p>
                            </div>
                            <div className="ft-contact-bx">
                                <img src="images/icon/icon3.png" alt=""/>
                                <h4 className="title">Email Contact</h4>
                                <p>{ contactInformation.email }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<div></div>)
    }
}

const mapStateToProps = state => { return state }

export default connect(mapStateToProps)(SectionInformationFooter)