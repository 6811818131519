import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'
import ListBox from '../../../common/ListBox'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list = [{
        title: 'Panel feed wires:',
        content: 'Their cables are commonly black insulated THHN wire, and their.'
    }, {
        title: 'Triplex wires:',
        content: 'These are conductors between the weather heads and the power poles.'
    }, {
        title: 'Single Strand Wires:',
        content: 'Alike to the panel feed wires, these ones also use THHN wire.'
    }, {
        title: 'Non-metallic sheathed wires:',
        content: 'Also known as Romex. Counts with 2-3 conductors provided with a bare ground wire and plastic insulation.'
    }, {
        title: 'Main feeder wires:',
        content: 'These ones connect the service weather head to your building.'
    }]
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/electrical_wiring_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "All types" } partRed={ "of Electrical Wiring" } />
                            <p>
                                The main types of electrical wiring that we often install are:
                            </p>
                            <ListBox list={ list }/>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Wiring Inspections" } partRed={ "& More" } />
                            <p>
                                The wiring inspections service we provide to you are performed by professionals that will check all of your cables, breaker boxes and electrical outlets and insure those ones that may require it. Your security it's our priority, and that's why our staff follows the highest technical standards.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/electrical_wiring_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;