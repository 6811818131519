import React from 'react'
import TitlePageBiColor from '../../common/TitlePageBiColor'
import ImageBorder from '../../common/ImageBorder'
import ListBox from '../../common/ListBox'
import SectionBlue from '../../common/SectionBlue'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    const section = {
        title: 'Experienced Commercial Electricians',
        list: [
        { content: 'Lighting Retrofit Solutions', icon: 'flaticon-factory-1'},
        { content: 'Energy Efficiency Solutions', icon: 'flaticon-factory' },
        { content: 'PLC Services', icon: 'flaticon-factory-1' },
        { content: 'Harmonic Mitigations', icon: 'flaticon-factory-1'},
        { content: 'Standby Generator System', icon: 'flaticon-factory' },
        { content: 'Speciality Voltages', icon: 'flaticon-factory-1' }
    ]}
    let list1 = [
        { content: 'Lighting Retrofits & Solutions Custom lighting solutions and retrofits.' },
        { content: 'Energy Efficiency Solutions Optimization of power systems for improving energy efficiency.' },
        { content: 'PLC Services Programmable Logic Control centers for process automation.' },
        { content: 'Harmonic Mitigation Reduction and avoidance of fines for distorted electrical waveforms.' },
        { content: 'Standby Generator Systems Emergency generator installation up to 2 megawatts.' },
        { content: 'Carousel & Conveyor Wiring Seamless wiring for material handling equipment.' },
        { content: 'Specialty Voltages Services for 380 volt, 440y/254 volt and more.' }
    ]

    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <ImageBorder src="images/services/industrial_services.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "Industrial System" } partRed={ "Upgrades" } />
                                <p>
                                    For the upgrade of your industrial system, we have a purpose to lower the downtime and maximize the production and maintainability for you as much as possible. We will achieve it by capacitating your employees with the most modern courses, and by improving your equipment providing you with the newest technologies through our completely reliable suppliers, that can provide us with the highest quality equipment on the market.
                                </p>
                                <p>
                                    This will keep your employees working, instead of patching up. We are completely capable of performing any kind of upgrade. We can also repair your industrial equipment and, according to the specific damage, we can fix it in place or in our shop.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Industrial Electrical" } partRed={ "Contractors" } />
                                <p>
                                    Our industrial electrical contractors are the most qualified ones you can possibly find, thanks to their constant training on new technologies. The job of our expert electrician is to do their  job as fast and safely as possible, by the highest quality standards that our clients expect and deserve.
                                </p>
                                <TitlePageBiColor partBlack={ "Industrial Electric" } partRed={ "Installations" } />
                                <p>
                                    For industrial electric installations we have an entire department in charge of satisfying your specific requirements, by delivering to you exactly what you have in mind for your industry. This department is conformed by the most qualified and trained professionals, and with the most modern equipment, to perform the most innovative and secure results that will exceed your expectations.
                                </p>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <ListBox list={ list1 }/>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInLeft" { ...propForEffects }>
                            <div className="align-self-center">
                                <ImageBorder src="images/services/electrical_repair.jpg"/>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                            <TitlePageBiColor partBlack={ "Industrial Electrical" } partRed={ "Maintenance" } />
                            <p>
                                The maintenance we perform on the electric industrial system of your company is the most complete one of the market. Our service includes the project, production, installation, commissioning, and putting into operation any upgrade you need, in the exact moment you need it and in the most complete way to satisfy your entire expectations, no matter how impossible you think that they can be.
                            </p>
                            <p>
                                Our first quality tooling and equipment help our fully capable employees, to perform their work in the quickest and most professional manner. Our purpose is to earn your trust in us through our work.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <SectionBlue { ...section } />
        </div>
    )
}
export default Content;