import React from 'react'
import { connect } from 'react-redux'
import changeIndexAccordion from '../../redux/actions/changeIndexAccordion'
import Constans from '../common/Constants'

const TopBar = props => {
    return (
        <div className="top-bar text-black">
			<div className="container">
				<div className="row d-flex justify-content-between">
					<div className="dlab-topbar-left"></div>
					<div className="dlab-topbar-right topbar-social">
						<ul>
							<li><a href="#" className="site-button-link facebook hover"><i className="fab fa-facebook"></i></a></li>
							<li><a href="#" className="site-button-link instagram hover"><i className="fab fa-instagram"></i></a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeIndexAccordion }

export default connect(mapStateToProps,mapDispatchToProps)(TopBar)