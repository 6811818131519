import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/breaker_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Breaker Box Wiring" } partRed={ "and Service Contractor" } />
                            <p>
                                Our trained electricians are perfectly capable of installing the breaker box wiring you need for your home or your company, on the wall of the main service wires. Normally, these main service wires will enter your home or company, through a big metallic conductor, and the service panel will connect to it through a gap on the superior part of the switch panel. When the whole breaker box wiring installation is finished, our electricians ensure that everything is working correctly by turning on the energy.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Breaker Box" } partRed={ "Repairs and More" } />
                            <p>
                                For the repair of your breaker box, our electricians are the most qualified professionals you can possibly find to do the specific kind of repair work you need to make in your home or in your company. He will know how to fix the fault, no matter how serious it may be, with the professionalism that distinguishes us.
                            </p>
                            <TitlePageBiColor partBlack={ "Dependable Service" } partRed={ "When You Need It" } />
                            <p>
                                We know how stressful it can be to find a trustworthy service, a service you can rely on even when there is a fault. If an urgent action is required, we will respond immediately. Our qualified professionals will be there for you and give you their best when you need it most.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/breaker_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;