import React from 'react'
import { ourServices } from '../common/Constants'
import { Link } from 'react-router-dom'
import ScrollTopFunction from '../common/ScrollTopFunction'

const OurProject = () => {
  return (
    <div className="section-full content-inner bg-gray" id="services">
      <div className="container">
        <div className="section-head text-black text-center">
            <h2 className="title">Our Services</h2>
        </div>
        <div className="row">
          {
          ourServices.map((item, key)=>{
            return(
              <div key={key} className="col-lg-4 col-md-6 col-sm-12 m-b30 wow bounceInUp" data-wow-duration="2s" data-wow-delay="0.3s">
                <div className="icon-bx-wraper bx-style-1 p-a30 center fly-box bg-white">
                  <div className="icon-lg m-b20">
                    <Link to={item.link} className="icon-cell"><img src={item.image} alt=""/></Link>
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte text-uppercase">{item.title}</h5>
                    <ul>
                        {item.detaItems.map(function(d, index){
                            return (<p className="text-justify" key={index}> {d} </p>)
                        })}
                    </ul>
                    <Link to={item.link}>
                      <button onClick={ ScrollTopFunction } className="site-button btnhover19">Read More</button>
                    </Link>
                  </div>
                </div>
              </div>
              )
              })
            }
          </div>
        </div>
      </div>
    )
}

export default OurProject
