import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'
import ListBox from '../../../common/ListBox'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list = [{
        content: 'Our highly capable and constantly trained professionals.'
    }, {
        content: 'Our modern machinery allows us to give you the best and safest results'
    }, {
        content: 'The periodic upgrades we constantly apply on our entire work tools to satisfy your expectations.'
    }]
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/gfci_outlet_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "GFCI Outlets" } partRed={ "Installation" } />
                            <p>
                                The GFCI outlet is very resilient. This outlet has a built-in-circuit breaker that interrupts the flow of electricity when it senses a current leak or a ground fault, only if it's properly installed. Our company counts with highly capable professionals and the ultimate machinery and tools, to ensure you the safety of our work.
                            </p>
                            <TitlePageBiColor partBlack={ "GFCI Outlet" } partRed={ "Wiring" } />
                            <p>
                                The internet instructions of how to install a GFCI outlet wiring are a common thing, but most common are the home accidents of people that tried to perform them. That’s why we offer you the service of performing it ourselves, securing the safety of yourself and of your business.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "GFCI Outlets" } partRed={ "Replacement" } />
                            <p>
                                When your GFCI outlet pauses your work and the reset button doesn't restore power, we know that you must call us to replace it. The risks you are exposed to if you try to do so yourself are too many to put your life in danger. Put your trust in us, instead.
                            </p>
                            <TitlePageBiColor partBlack={ "Reliable GFCI" } partRed={ "Outlet Installation" } />
                            <p>
                                The installation of GFCI outlets we provide you are completely reliable for the following reasons:
                            </p>
                            <ListBox list={ list }/>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/gfci_outlet_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;