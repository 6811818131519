import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/outdoor_lighting_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Expert Outdoor" } partRed={ "Lighting Solutions" } />
                            <p>
                                Our trained electricians can design and implement an outdoor lighting system that meets your business needs. With cost-effective technologies, we can provide you a solution that will beat your expectations. Thanks to our long experience in the industry, we are sure of our capacity to deliver the right solution for your business.
                            </p>
                            <TitlePageBiColor partBlack={ "Illumination Systems " } partRed={ "& More" } />
                            <p>
                                The illumination systems include an extensive broad range of applications, with an equally extensive broad range of design specifications and requirements. We are prepared to provide you with the specific pattern of illumination that you require for everyday usage, in the most secure and modern way, thanks to our highly capacitated professionals.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Energy Efficient" } partRed={ "Outdoor Lighting" } />
                            <p>
                                For outdoor lighting, we know that the efficiency of the energy is the main matter to solve. That's why we count with LED lamps and assorted technologies that provide you higher illumination at a lower cost, and the convenient benefit of its long lifespan allows you to forget to change your burnt lamps every month.
                            </p>
                            <p>
                                Our longtime experience of energy efficient outdoor lighting services serve us to reach your expectations like no other. Thanks to our strong relationship with our manufacturers, we can also provide you with the most cost-effective solution. Because of our long experience –from small commercial buildings to large warehouses–, we know we can implement any lighting solution you require.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/outdoor_lighting_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;