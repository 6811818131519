import React from 'react'
import TopBar from './TopBar'
import Logo from './Logo'
import MainNav from './MainNav'
import SearchPopUp from './SearchPopUp'
import ToggleButton from './ToggleButton'
import ExtraNav from './ExtraNav'
// import ContactHeader from './ContactHeader'

const Header = () => {
    return (
        <header className='site-header mo-left header-transparent box-header header'>
            <TopBar/>
            {/* <div className='middle-bar bg-white'>
                <div className='container'>
                    <div className='middle-area'>
                        <Logo class='logo-header' src='images/LogoSVEC.png'></Logo>
                        <ContactHeader/>
                    </div>
                </div>
            </div> */}
            <div className='sticky-header main-bar-wraper navbar-expand-lg'>
                <div className='main-bar clearfix'>
                    <div className='container clearfix' style={{ backgroundColor: '#033B83' }}>
                        <Logo class='logo-header mostion' src='images/LogoSVECHorizontal.png'></Logo>
                        <ToggleButton/>
                        <ExtraNav/>
                        <SearchPopUp/>
                        <MainNav/>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header