import React from 'react'
import ScrollTopFunction from '../common/ScrollTopFunction'
import { Link } from 'react-router-dom'

const ExtraNav = () => {
    const handleClick = () => {
		ScrollTopFunction()
	}
    return (
        <div className='extra-nav'>
            <Link to='/get-a-quote' onClick={ handleClick } className='site-button radius-no'>GET A QUOTE</Link>
        </div>
    )
}

export default ExtraNav