import React from 'react'
import { Link } from 'react-router-dom'
import { navs } from '../common/Constants'
import ScrollTopFunction from '../common/ScrollTopFunction'

const Copyright = () => {
    let date = new Date()
    let year = date.getFullYear()
    const  navIndexs = [1,3,4]

    return (
        <div className='footer-bottom'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-6 col-sm-6 text-left'>
                        <span>© Copyright { year } SV Electrical Contractors, All Rights Reserved</span>
                    </div>
                    <div className='col-md-6 col-sm-6 text-right '>
                        <div className='widget-link'>
                            <ul>{
                                navIndexs.map( (index,key) => {
                                    return(
                                        <li key={ key } onClick={ () => ScrollTopFunction() }><Link className='footerLinks text-white' to={ navs[index].path }> { navs[index].name }</Link></li>
                                    )
                                })
                            }</ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Copyright