import React from 'react';
import { Switch, Route, Redirect  } from 'react-router-dom'
import { connect } from 'react-redux'
import Head from './components/common/Head'

//---------------- HOME ----------------
import MainContent from './components/mainContent'

//---------------- ABOUT---------------
import About from './components/pages/about'

//---------------- SERVICES AREA ---------------
import ServicesArea from './components/pages/servicesArea';

import LocalServiceArea from './components/pages/localService';
import Consulting from './components/pages/consulting';

//---------------- OUR SERVICES ---------------
import Services from './components/pages/services';
import ComercialElectricalServices from './components/pages/services/comercialElectricalServices';
import MechanicalServices from './components/pages/services/mechanicalServices';
import IndustrialServices from './components/pages/industrial';
import MultifamilyElectricalServices from './components/pages/services/multifamilyElectricalServices';
import ProjectCoordination from './components/pages/services/projectCoordination';
// SERVICES - > LOW VOLTAGE SYSTEMS
import LowVoltageSystems from './components/pages/services/lowVoltageSystems';
import AccessControl from './components/pages/services/lowVoltageSystems/accessControl/index.js';
import FiberOptic from './components/pages/services/lowVoltageSystems/fiberOptic';
import FireAlarm from './components/pages/services/lowVoltageSystems/fireAlarm';
import StructureCabling from './components/pages/services/lowVoltageSystems/structureCabling';
import InstrumentationAndStartUp from './components/pages/services/lowVoltageSystems/instrumentationAndStartUp';
import TestingServices from './components/pages/services/lowVoltageSystems/testingServices';
import Telecommunications from './components/pages/services/lowVoltageSystems/telecommunications';


// ----------------COMMERCIAL ----------------------------------
import Comercial from './components/pages/commercial';
import BrakerBoxWiring from './components/pages/commercial/brakerBoxWiring';
import DataWiringNetworking from './components/pages/commercial/dataWiringNetworking';
import ElectricalInspections from './components/pages/commercial/electricalInspections';
import ElectricalOutletInstalation from './components/pages/commercial/electricalOutletInstalation';
import ElectricalWiring from './components/pages/commercial/electricalWiring';
import GFCIInstalation from './components/pages/commercial/gfciInstalation';
import OfficeWiringSolutions from './components/pages/commercial/officeWiringSolutions';
import OutdoorLighting from './components/pages/commercial/outdoorLighting';
import PhoneWiringNetworking from './components/pages/commercial/phoneWiringNetworking';
import PowerLightingDesing from './components/pages/commercial/powerLightingDesing';

//---------------- INDUSTRIAL --------------
import LightingRetrofitSolutions from './components/pages/industrial/lightingRetrofitSolutions';
import EnergyEfficiencySolutions from './components/pages/industrial/energyEfficiencySolutions';
import PLCServices from './components/pages/industrial/plcServices';
import HarmonicMitigations from './components/pages/industrial/harmonicMitigations';
import StandbyGeneratorSystem from './components/pages/industrial/standbyGeneratorSystem';
import CarouselConveyorWiring from './components/pages/industrial/carouselConveyorWiring';
import SpecialityVoltages from './components/pages/industrial/specialityVoltages';

//---------------- CONTACT ----------------
import Contact from './components/pages/contact'

//---------------- GET A QUOTE ----------------
import GetAQuote from './components/pages/getQuote'

//---------------- UNDER CONSTRUCTION PAGE ----------------
import UnderConstructionPage from './components/pages/underConstructionPage'

const Content = props => {

    if(!props.state.loggedIn) {
        return <Redirect to="/login" />
    }

    return (
        <Switch>
            <Route exact path='/'>
                <Head title='Home' page='home'></Head>
                <MainContent></MainContent>
            </Route>

            {/* //-----------ABOUT--------------- */}
            <Route exact path='/about'>
                <Head title='About Us' page='about'></Head>
                <About/>
            </Route>

            {/* //-----------SERVICES AREA--------------- */}
            <Route exact path='/services-area'>
                <Head title='Services Area' page='servicesArea'/>
                <ServicesArea/>
            </Route>

            {/* //-----------OUR SERVICES--------------- */}
            <Route exact path='/services'>
                <Head title='Services' page='services'/>
                <Services/>
            </Route>

            {/* //SERVICES -> Low Voltage Systems */}
            <Route exact path='/low-voltage-systems'>
                <Head title='Low Voltage Systems' page='lowVoltageSystems'/>
                <LowVoltageSystems/>
            </Route>
            <Route exact path='/access-control'>
                <Head title='Access Control' page='accessControl'/>
                <AccessControl/>
            </Route>
            <Route exact path='/fiber-optic'>
                <Head title='Fiber Optic' page='fiberOptic'/>
                <FiberOptic/>
            </Route>
            <Route exact path='/fire-alarm'>
                <Head title='Fire Alarm' page='fireAlarm'/>
                <FireAlarm/>
            </Route>
            <Route exact path='/instrumentation-and-start-up'>
                <Head title='Instrumentation And Start Up' page='instrumentationAndStartUp'/>
                <InstrumentationAndStartUp/>
            </Route>
            <Route exact path='/structure-cabling'>
                <Head title='Structure Cabling' page='structureCabling'/>
                <StructureCabling/>
            </Route>
            <Route exact path='/telecommunications'>
                <Head title='Telecommunications' page='telecommunications'/>
                <Telecommunications/>
            </Route>
            <Route exact path='/testing-services'>
                <Head title='Testing Services' page='testingServices'/>
                <TestingServices/>
            </Route>
            {/* //SERVICES -> Comercial Electrical Services */}
            <Route exact path='/comercial-electrical'>
                <Head title='Comercial Electrical Services' page='comercialElectricalServices'/>
                <ComercialElectricalServices/>
            </Route>
            {/* //SERVICES -> Mechanical Services */}
            <Route exact path='/mechanical'>
                <Head title='Mechanical Services' page='mechanicalServices'/>
                <MechanicalServices/>
            </Route>
            {/* //COMMERCIAL */}
            <Route exact path='/comercial'>
                <Head title='Comercial' page='comercial'/>
                <Comercial/>
            </Route>

            <Route exact path='/breaker-box-wiring'>
                <Head title='Breaker Box Wiring' page='brakerBoxWiring'/>
                <BrakerBoxWiring/>
            </Route>

            <Route exact path='/data-wiring-networking'>
                <Head title='Data Wiring Networking' page='dataWiringNetworking'/>
                <DataWiringNetworking />
            </Route>

            <Route exact path='/electrical-inspections'>
                <Head title='Electrical Inspections' page='electricalInspections'/>
                <ElectricalInspections />
            </Route>

            <Route exact path='/electrical-outlet-instalation'>
                <Head title='Electrical Outlet Instalation' page='electricalOutletInstalation'/>
                <ElectricalOutletInstalation />
            </Route>

            <Route exact path='/electrical-wiring'>
                <Head title='Electrical Wiring' page='electricalWiring'/>
                <ElectricalWiring/>
            </Route>

            <Route exact path='/gfci-instalation'>
                <Head title='GFCI Instalation' page='gfciInstalation'/>
                <GFCIInstalation/>
            </Route>

            <Route exact path='/office-wiring-solutions'>
                <Head title='Office & Wiring Solutions' page='officeWiringSolutions'/>
                <OfficeWiringSolutions/>
            </Route>

            <Route exact path='/outdoor-lighting'>
                <Head title='Outdoor Lighting' page='outdoorLighting'/>
                <OutdoorLighting/>
            </Route>

            <Route exact path='/phone-wiring-networking'>
                <Head title='Phone Wiring & Networking' page='phoneWiringNetworking'/>
                <PhoneWiringNetworking/>
            </Route>

            <Route exact path='/power-lighting-desing'>
                <Head title='Power Lighting Desing' page='powerLightingDesing'/>
                <PowerLightingDesing/>
            </Route>

            {/* //SERVICES -> Industrial Services */}
            <Route exact path='/industrial'>
                <Head title='Industrial Services' page='industrialServices'/>
                <IndustrialServices/>
            </Route>

            <Route exact path='/lighting-retrofit-solutions'>
                <Head title='Lighting Retrofit Solutions' page='lightingRetrofitSolutions'/>
                <LightingRetrofitSolutions/>
            </Route>

            <Route exact path='/energy-efficiency-solutions'>
                <Head title='Energy Efficiency Solutions' page='energyEfficiencySolutions'/>
                <EnergyEfficiencySolutions/>
            </Route>

            <Route exact path='/plc-services'>
                <Head title='PLC Services' page='plcServices'/>
                <PLCServices/>
            </Route>

            <Route exact path='/harmonic-mitigations'>
                <Head title='Harmonic Mitigations' page='harmonicMitigations'/>
                <HarmonicMitigations/>
            </Route>

            <Route exact path='/standby-generator-system'>
                <Head title='Standby Generator System' page='standbyGeneratorSystem'/>
                <StandbyGeneratorSystem/>
            </Route>

            <Route exact path='/carousel-conveyor-Wiring'>
                <Head title='Carousel Conveyor Wiring' page='carouselConveyorWiring'/>
                <CarouselConveyorWiring/>
            </Route>

            <Route exact path='/speciality-voltages'>
                <Head title='Speciality Voltages' page='specialityVoltages'/>
                <SpecialityVoltages/>
            </Route>

            {/* //SERVICES -> Multifamily Electrical Services */}
            <Route exact path='/multifamily-electrical'>
                <Head title='Multifamily Electrical Services' page='multifamilyElectricalServices'/>
                <MultifamilyElectricalServices/>
            </Route>
            {/* //SERVICES ->  Project Coordination */}
            <Route exact path='/project-coordination'>
                <Head title='Project Coordination' page='projectCoordination'/>
                <ProjectCoordination/>
            </Route>
            {/* //-----------CONSULTING--------------- */}
            <Route exact path='/electrical-consulting'>
                <Head title='Electrical Consulting' page='electricalConsulting'/>
                <Consulting/>
            </Route>
            {/* //-----------LOCAL SERCICES AREA--------------- */}
            <Route exact path='/local-services-area'>
                <Head title='Local Electricians Service Area' page='localServiceArea'/>
                <LocalServiceArea/>
            </Route>

            {/* //-----------CONTACT--------------- */}
            <Route exact path='/contact'>
                <Head title='Contact' page='contact'></Head>
                <Contact></Contact>
            </Route>

            {/* //-----------GET A QUOTE--------------- */}
            <Route exact path='/get-a-quote'>
                <Head title='Get a Quote' page='getQuote'></Head>
                <GetAQuote></GetAQuote>
            </Route>

            {/* //-------UNDER CONSTRUCTION PAGE ------ */}
            <Route exact path='/underConstructionPage'>
                <UnderConstructionPage/>
            </Route>
        </Switch>
    )
}

const mapStateToProps = state => { return state }

export default connect(mapStateToProps)(Content)