import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/electrical_outlet_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Electrical" } partRed={ "Outlet Instalation" } />
                            <p>
                                For electrical outlet installation, we must know the number of poles to feed the charge, the maximum tension it can be submitted, and the maximum electric current a device can receive without suffering damage and cause danger.
                            </p>
                            <p>
                                We count on our staff with highly qualified technicians of electrical outlet installations that will listen carefully to your specific requirements, expectations and needs, and will fulfill them in the most complete way.
                            </p>
                            <p>
                                As a complement of our work, we offer you a tracking of it by checking, after a certain amount of time, that everything it's okay and that it still doesn’t represent a danger for you.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Upgrading " } partRed={ "Power Outlets" } />
                            <p>
                                When you discover irregularities on your electrical outlets, like brown or black discoloration spots on them, produced possibly by smoke or burn, or you frequently find tripping circuits, you must upgrade them as soon as possible. We count with the best equipment and professionals to deliver the exact secure upgrade you need.
                            </p>
                            <TitlePageBiColor partBlack={ "Installing" } partRed={ "Electrical Outlets" } />
                            <p>
                                With this service our technicians assess the state of your electrical outlets to avoid fire risk and provide you with the most qualified and complete inspections of your entire electrical circuit, including your smoke alarms, your carbon monoxide detectors, your weather-proofing protection and shock prevention. The protection of your life is our commitment.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/electrical_outlet_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;