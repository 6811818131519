import React , { useEffect } from 'react';
import { connect } from 'react-redux'
import changeNavSelected from '../../redux/actions/changeNavSelected'
import SliderMain from './SliderMain'
import Init from '../common/Init'
import CallAction from './CallAction'
import CardsAndForm from './CardsAndForm';
import GallerySection from './GallerySection';
import OurServices from './OurServices'

const MainContent = props => {

    useEffect(() => {
        props.changeNavSelected(0)
        Init.initRevSlider()
    },[])

    return(
        <div className='page-content bg-white'>
            <SliderMain/>
            <div className="content-block">
                <CardsAndForm/>
                <GallerySection/>
                <OurServices/>
                <CallAction/>
            </div>
        </div>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps, mapDispatchToProps)(MainContent)