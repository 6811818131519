import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'
import ListBox from '../../../common/ListBox'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list = [{
        title: 'Residential:',
        content: 'We know how important it is the electrical safety in your home, because of the wellbeing of your family, and that is why we provide you with exceptional customer products and an exceptional customer service that count with the highest quality.'
    }, {
        title: 'Commercial:',
        content: 'Our expertise let us provide you with the most qualified solution for your business'
    }]
    let list2 = [{
        title: 'Electrical projects:',
        content: 'Our experience allows us to accomplish our purpose to accomplish the work you entrusted to us in the most complete way, ensuring the safety of all the people nearby and improving their lifestyle.'
    }, {
        title: 'Renovations:',
        content: 'You can count with this option we offer when you are trying to avoid the replacement of something broken or damaged. The upgrading we perform is the most professional one you can find.'
    }, {
        title: 'Others:',
        content: 'When we said ´more´, we refer to the professional accomplishment of something that we may not currently perform, but that we will do to achieve your satisfaction'
    }]
    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <ImageBorder src="images/services/multifamily_1.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "Full-Service Electrical" } partRed={ "Contracting" } />
                                <p>
                                Thanks to our large experience and the licensed service providers we can count on to achieve and fulfill your requirements, expectations and needs, we can proudly say that we are a company that is completely able to offer you a fully licensed, bonded and insured electrical contracting service on the following areas:
                                </p>
                                <ListBox list={ list }/>
                            </div>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "Multi-Family Electrical Projects" } partRed={ ", Renovations & More" } />
                                <p>
                                    For multi-family electrical services, we count with specialists in providing personalized, prompt and efficient solutions, and the right equipment to perform:
                                </p>
                                <ListBox list={ list2 }/>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageBorder src="images/services/multifamily_2.jpg"/>
                            <div className="align-self-center">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;