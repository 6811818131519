import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'
import ListBox from '../../../common/ListBox'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list1 = [
        { content: 'Customized maintenance, adapted to meet your specific application needs.' },
        { content: 'General inspections.' },
        { content: 'Scheduled inspections and service of the systems of coolant, air, starting and lubrication.' }
    ]
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/standby_generator_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Installing Standby" } partRed={ "Generators" } />
                            <p>
                                We know how dangerous it could be for you to try to install standby generators all by yourself, because our training and knowledge in this area gave us wisdom about its risks and how to avoid them properly. Our qualified and trained professionals, by the other hand, have the most modern machinery to deliver to you the exact result you deserve, in a customized way.
                            </p>
                            <TitlePageBiColor partBlack={ "Generator Power" } partRed={ "Requirements" } />
                            <p>
                                In our company, the requirements of power generators vary among the different kinds of it that there are, and help us to know which is the exact one you need from all of them. Once we identify the type you need, we will proceed to install it for you in the most complete, secure and professional way possible.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Complete Standby Generator" } partRed={ "Services" } />
                            <p>
                                The services we offer you for standby generators are the most complete ones, because they are performed in the safest and most professional way, and they consist of:
                            </p>
                            <ListBox list={ list1 } />
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Standby Generator" } partRed={ "Installation" } />
                            <p>
                                For the installation of stand by generators, we have the most modern of them and the most competent professionals. This allows us to ensure not only the safety of the final work we will perform, something crucial to guarantee the protection of the people that will be nearby, but also the superior quality of it.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className= "col-sm-12 col-md-12 col-lg-12 wow fadeInRight" { ...propForEffects }>
                        <ImageBorder src="images/services/standby_generator_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;