/*global jQuery */
const mapInit = {
	initRevSlider: () => {
		if(window.$("#welcome").revolution === undefined){
			window.revslider_showDoubleJqueryError("#welcome");
		}else{
			window.$("#welcome").show().revolution({
				sliderType:"standard",
				jsFileLocation:"plugins/revolution/revolution/js/",
				sliderLayout:"fullscreen",
				dottedOverlay:"none",
				delay:9000,
				particles: {startSlide: "first", endSlide: "last", zIndex: "1",
					particles: {
						number: {value: 25}, color: {value: "#ffffff"},
						shape: {
							type: "circle", stroke: {width: 0, color: "#ffffff", opacity: 1},
							image: {src: ""}
						},
						opacity: {value: 1, random: true, min: 0.25, anim: {enable: true, speed: 3, opacity_min: 0, sync: false}},
						size: {value: 2, random: true, min: 0.5, anim: {enable: true, speed: 5, size_min: 1, sync: false}},
						line_linked: {enable: false, distance: 150, color: "#ffffff", opacity: 0.4, width: 1},
						move: {enable: true, speed: 2, direction: "none", random: true, min_speed: 1, straight: false, out_mode: "out"}},
					interactivity: {
						events: {onhover: {enable: false, mode: "bubble"}, onclick: {enable: false, mode: "repulse"}},
						modes: {grab: {distance: 400, line_linked: {opacity: 0.5}}, bubble: {distance: 400, size: 40, opacity: 0.5}, repulse: {distance: 200}}
					}
				},
				revealer: {
					direction: "open_horizontal",
					color: "#ffffff",
					duration: "1500",
					delay: "0",
					easing: "Power2.easeInOut",
					overlay_enabled: true,
					overlay_color: "#000000",
					overlay_duration: "1500",
					overlay_delay: "0",
					overlay_easing: "Power2.easeInOut",
					spinner: "1",
					spinnerColor: "#006dd2",
				},
				navigation: {
					keyboardNavigation:"off",
					keyboard_direction: "horizontal",
					mouseScrollNavigation:"off",
					mouseScrollReverse:"default",
					onHoverStop:"off",
					arrows: {
						style:"uranus",
						enable:true,
						hide_onmobile:false,
						hide_onleave:false,
						tmp:'',
						left: {
							h_align:"left",
							v_align:"center",
							h_offset:10,
							v_offset:0
						},
						right: {
							h_align:"right",
							v_align:"center",
							h_offset:10,
							v_offset:0
						}
					}
				},
				viewPort: {
					enable:true,
					outof:"wait",
					visible_area:"80%",
					presize:true
				},
				responsiveLevels:[1240,1025,778,480],
				visibilityLevels:[1240,1025,778,480],
				gridwidth:[1240,1025,778,480],
				gridheight:[970,970,960,720],
				lazyType:"single",
				parallax: {
					type:"scroll",
					origo:"slidercenter",
					speed:400,
					speedbg:0,
					speedls:0,
					levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
				},
				shadow:0,
				spinner:"spinner2",
				stopLoop:"on",
				stopAfterLoops:0,
				stopAtSlide:1,
				shuffle:"off",
				autoHeight:"off",
				fullScreenAutoWidth:"off",
				fullScreenAlignForce:"off",
				fullScreenOffsetContainer: "",
				fullScreenOffset: "",
				disableProgressBar:"on",
				hideThumbsOnMobile:"off",
				hideSliderAtLimit:0,
				hideCaptionAtLimit:0,
				hideAllCaptionAtLilmit:0,
				debugMode:false,
				fallbacks: {
					simplifyAll:"off",
					nextSlideOnWindowFocus:"off",
					disableFocusListener:false,
				}
			});
		}
	},
	initRevSlider14: () => {
		if(window.$("#rev_slider_14_1").revolution === undefined){
			window.revslider_showDoubleJqueryError("#rev_slider_14_1");
		}else{
			window.$("#rev_slider_14_1").show().revolution({
				sliderType:"hero",
				jsFileLocation:"plugins/revolution/revolution/js/",
				sliderLayout:"fullscreen",
				dottedOverlay:"none",
				delay:9000,
				particles: {startSlide: "first", endSlide: "last", zIndex: "6",
					particles: {
						number: {value: 100}, color: {value: "#ffffff"},
						shape: {
							type: "circle", stroke: {width: 0, color: "#ffffff", opacity: 1},
							image: {src: ""}
						},
						opacity: {value: 1, random: true, min: 0.25, anim: {enable: false, speed: 3, opacity_min: 0, sync: false}},
						size: {value: 3, random: true, min: 0.5, anim: {enable: false, speed: 40, size_min: 1, sync: false}},
						line_linked: {enable: false, distance: 150, color: "#ffffff", opacity: 0.4, width: 1},
						move: {enable: true, speed: 1, direction: "top", random: true, min_speed: 1, straight: false, out_mode: "out"}},
					interactivity: {
						events: {onhover: {enable: true, mode: "bubble"}, onclick: {enable: false, mode: "repulse"}},
						modes: {grab: {distance: 400, line_linked: {opacity: 0.5}}, bubble: {distance: 400, size: 0, opacity: 0.01}, repulse: {distance: 200}}
					}
				},
				navigation: {
				},
				responsiveLevels:[1240,1024,778,480],
				visibilityLevels:[1240,1024,778,480],
				gridwidth:[1240,1024,778,480],
				gridheight:[868,768,960,720],
				lazyType:"none",
				parallax: {
					type:"mouse",
					origo:"slidercenter",
					speed:400,
					levels:[1,2,3,4,5,10,15,20,25,46,47,48,49,50,51,55],
				},
				shadow:0,
				spinner:"off",
				autoHeight:"off",
				fullScreenAutoWidth:"off",
				fullScreenAlignForce:"off",
				fullScreenOffsetContainer: ".header",
				fullScreenOffset: "",
				disableProgressBar:"on",
				hideThumbsOnMobile:"off",
				hideSliderAtLimit:0,
				hideCaptionAtLimit:0,
				hideAllCaptionAtLilmit:0,
				debugMode:false,
				fallbacks: {
					simplifyAll:"off",
					disableFocusListener:false,
				}
			});
		}
	},
	initRevSlider11: () => {
		if(window.$("#rev_slider_11_1").revolution === undefined){
			window.revslider_showDoubleJqueryError("#rev_slider_14_1");
		}else{
			window.$("#rev_slider_11_1").show().revolution({
				ssliderType:"standard",
				sliderLayout:"fullscreen",
				dottedOverlay:"none",
				delay:90000,
				navigation: {
					keyboardNavigation:"off",
					keyboard_direction: "horizontal",
					mouseScrollNavigation:"off",
					mouseScrollReverse:"default",
					onHoverStop:"off",
					touch:{
						touchenabled:"on",
						swipe_threshold: 75,
						swipe_min_touches: 1,
						swipe_direction: "horizontal",
						drag_block_vertical: false
					},
					bullets: {
						enable:true,
						hide_onmobile:true,
						hide_under:960,
						style:"zeus",
						hide_onleave:false,
						direction:"horizontal",
						h_align:"right",
						v_align:"bottom",
						h_offset:80,
						v_offset:50,
						space:5,
						tmp:'<span class="tp-bullet-image"></span><span class="tp-bullet-imageoverlay"></span><span class="tp-bullet-title">{{title}}</span>'
					}
				},
				responsiveLevels:[1240,1240,778,778],
				visibilityLevels:[1240,1240,778,778],
				gridwidth:[1640,1640,778,778],
				gridheight:[768,768,960,960],
				lazyType:"none",
				parallax: {
					type:"mouse",
					origo:"enterpoint",
					speed:400,
					speedbg:0,
					speedls:0,
					levels:[2,4,6,8,10,12,14,16,45,50,47,48,49,50,0,50],
				},
				shadow:0,
				spinner:"off",
				stopLoop:"off",
				stopAfterLoops:-1,
				stopAtSlide:-1,
				shuffle:"off",
				autoHeight:"off",
				fullScreenOffsetContainer: ".header",
				disableProgressBar:"on",
				hideThumbsOnMobile:"off",
				hideSliderAtLimit:0,
				hideCaptionAtLimit:0,
				hideAllCaptionAtLilmit:0,
				debugMode:false,
				fallbacks: {
					simplifyAll:"off",
					nextSlideOnWindowFocus:"off",
					disableFocusListener:false,
				}
			});
		}
	},
	initCompaniesSlider: () => {
		var sync1 = window.$("#sync1");
		var sync2 = window.$("#sync2");
		var slidesPerPage = 4;
		var syncedSecondary = true;

		sync1.owlCarousel({
			items : 1,
			nav: true,
			autoplaySpeed: 3000,
			navSpeed: 3000,
			paginationSpeed: 3000,
			slideSpeed: 3000,
			smartSpeed: 3000,
			autoplay: 3000,
			dots: false,
			loop: true,
			responsiveRefreshRate : 200,
			navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
		}).on('changed.owl.carousel', syncPosition);

		sync2.on('initialized.owl.carousel', function () {
			sync2.find(".owl-item").eq(0).addClass("current");
			}).owlCarousel({
			items : slidesPerPage,
			dots: false,
			nav: false,
			margin:20,
			autoplaySpeed: 3000,
			navSpeed: 3000,
			paginationSpeed: 3000,
			slideSpeed: 3000,
			smartSpeed: 3000,
			autoplay: 3000,
			slideBy: slidesPerPage,
			responsiveRefreshRate : 100,
			responsive:{
				0:{ items:2 },
				480:{ items:2 },
				768:{ items:3 },
				1024:{ items:4 },
				1400:{ items:4 }
			}
		}).on('changed.owl.carousel', syncPosition2);

		function syncPosition(el) {
			var count = el.item.count-1;
			var current = Math.round(el.item.index - (el.item.count/2) - .5);

			if(current < 0) { current = count; }
			if(current > count) { current = 0; }

			sync2
				.find(".owl-item")
				.removeClass("current")
				.eq(current)
				.addClass("current");
			var onscreen = sync2.find('.owl-item.active').length - 1;
			var start = sync2.find('.owl-item.active').first().index();
			var end = sync2.find('.owl-item.active').last().index();

			var currencyImg = window.$('.owl-item.active.current .logo-company');
			if(currencyImg && currencyImg[0]){
				currencyImg[0].style.display = 'none'
			}
			if(currencyImg && currencyImg[1]){
				currencyImg[1].style.display = 'block'
			}

			var imgswhite = window.$('.owl-item.active .logo-company').not('.owl-item.active.current .logo-company');
			var totalImg = imgswhite.length
			imgswhite.map((index,elem) => {
				if(index < totalImg) {
					elem.style.display = (index % 2 === 0) ? 'block' : 'none';
				}
			});

			if (current > end) {
				sync2.data('owl.carousel').to(current, 100, true);
			}
			if (current < start) {
				sync2.data('owl.carousel').to(current - onscreen, 100, true);
			}
		}

		function syncPosition2(el) {
			if(syncedSecondary) {
				var number = el.item.index;
				sync1.data('owl.carousel').to(number, 100, true);
			}
		}

		sync2.on("click", ".owl-item", function(e){
			e.preventDefault();
			var number = window.$(this).index();
			sync1.data('owl.carousel').to(number, 300, true);
		})
	},
	initGallery: () => {
		if(jQuery('#masonry, .masonry').length)
		{
			var self = window.$("#masonry, .masonry");
			if(jQuery('.card-container').length)
			{
				self.imagesLoaded(function () {
					self.masonry({
						gutterWidth: 15,
						isAnimated: true,
						itemSelector: ".card-container"
					});
				});
			}
		}
		if(jQuery('.filters').length)
		{
			jQuery(".filters").on('click','li',function(e) {
				e.preventDefault();
				var filter = window.$(this).attr("data-filter");
				self.masonryFilter({
					filter: function () {
						if (!filter) return true;
						return window.$(this).hasClass(filter);
					}
				});
			});
		}
		var lightGallery = function (){
			if((window.$('#lightgallery, .lightgallery').length > 0)){
				window.$('#lightgallery, .lightgallery').lightGallery({
					selector : '.check-km',
					loop:true,
					thumbnail:true,
					exThumbImage: 'data-exthumbimage'
				});
			}
		}
		lightGallery()
	},
	initScrollTop: () => {
		var scrollTop = jQuery("button.scroltop");
		/* page scroll top on click function */
		scrollTop.on('click',function() {
			jQuery("html, body").animate({
				scrollTop: 0
			}, 1000);
			return false;
		})

		jQuery(window).bind("scroll", function() {
			var scroll = jQuery(window).scrollTop();
			if (scroll > 900) {
				jQuery("button.scroltop").fadeIn(1000);
			} else {
				jQuery("button.scroltop").fadeOut(1000);
			}
		});
	},
	initDzCarrousel: () => {
		jQuery('.img-carousel-dots-nav').owlCarousel({
			loop:true,
			autoplaySpeed: 3000,
			navSpeed: 3000,
			paginationSpeed: 3000,
			slideSpeed: 3000,
			smartSpeed: 3000,
			autoplay: 3000,
			margin:30,
			nav:true,
			dots:true,
			navText: ['<i class="ti-arrow-left"></i>', '<i class="ti-arrow-right"></i>'],
			responsive:{
				0:{
					items:1
				},
				480:{
					items:2
				},
				1024:{
					items:3
				},
				1200:{
					items:4
				}
			}
		})
	},
	initForm: () => {
		if(jQuery('.dezPlaceAni').length)
		{
			window.$('.dezPlaceAni input, .dezPlaceAni textarea').on('focus',function(){
				window.$(this).parents('.form-group, .news-box').addClass('focused');
			});
			window.$('.dezPlaceAni input, .dezPlaceAni textarea').on('blur',function(){
				var inputValue = window.$(this).val();
				if ( inputValue === "" ) {
					window.$(this).removeClass('filled');
					window.$(this).parents('.form-group, .news-box').removeClass('focused');
				} else {
					window.$(this).addClass('filled');
				}
			})
		}
	}
}

export default mapInit
