import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'
import ListBox from '../../../common/ListBox'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list1 = [
        { content: 'Motion sensitive smart lighting. This will allow you to save energy and to lower your maintenance costs.' },
        { content: 'Wireless connections. This will assist you in the establishment of firm communications between smart lighting pieces.' }
    ]
    let list2 = [
        { content: 'Replacement options of lighting.' },
        { content: 'Metering the energy consumption.' },
        { content: 'Analysis of the flue gas and HVAC systems.' },
        { content: 'Performance of improvements and upgrades.' },
        { content: 'Study your countability, and think of ways to optimize it as much as possible' }
    ]
    let list3 = [
        { content: 'Insulation of your water heater and pipes.' },
        { content: 'Cleanance and replacement of your filters.' },
        { content: 'Checking of your faucets for leaks.' },
        { content: 'Installation of a programmable thermostat.' },
        { content: 'Replacement of your appliances before they die.' }
    ]
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/energy_efficiency_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Solutions for Energy" } partRed={ "Efficiency" } />
                            <p className="m-b5">
                                We offer you the following solutions to solve your energy efficiency problems:
                            </p>
                            <ListBox list={ list1 }/>
                            <TitlePageBiColor partBlack={ "Energy" } partRed={ "Audits" } />
                            <p>
                                The energy audits we perform consists in a meticulous personalized inspection, study and analysis of the energy flows of your company. We focus on energy savings opportunities for you, and that's why we make a complete report of all the flaws that can be improved and upgraded to achieve your satisfaction and safety.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Energy Efficiency" } partRed={ "Solutions" } />
                            <p className="m-b5">
                                For solutions on matters of energy efficiency, we have the following options that will adapt to your specific requirements:
                            </p>
                            <ListBox list={ list2 }/>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageBorder src="images/services/energy_efficiency_2.jpg"/>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/energy_efficiency_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Incentives for Energy" } partRed={ "Efficiency" } />
                            <p className="m-b5">
                                We offer you the following solutions to solve your energy efficiency problems:
                            </p>
                            <ListBox list={ list3 }/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;