import React from 'react'
import TitlePageBiColor from '../../../../common/TitlePageBiColor'
import ImageBorder from '../../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/fiber_optic.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Fiber" } partRed={ "Optic" } />
                            <p>
                                We know that in this century, internet communication became an essential and crucial part of every company, home, and people in general. We also know which is the most discreet and the most secure option for the internet delivery of information.This is the reason why we are specialized in the installation, the maintenance and the repair of the cabling of fiber optics.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;