import React from 'react'
import { Link } from 'react-router-dom'

const PageBanner = props => {
    let backgroundImage = 'url(images/banner/' + props.img.name + '.' + props.img.type + ')'
    return (
        <div className='dlab-bnr-inr overlay-black-middle text-center bg-pt' style={{ backgroundImage: backgroundImage }}>
            <div className='container'>
                <div className='dlab-bnr-inr-entry align-m text-center' style={{ paddingTop: '115px' }}>
                    <h1 className='text-white'>{ props.title }</h1>
                    {/* <!-- BREADCRUMB ROW --> */}
                    <div className='breadcrumb-row'>
                        <ul className='list-inline'>
                            <li><Link to='./'>Home</Link></li>
                            <li>{ props.title }</li>
                        </ul>
                    </div>
                    {/* <!-- BREADCRUMB ROW END --> */}
                </div>
            </div>
        </div>
    )
}

export default PageBanner