import React from 'react'
import TitlePageBiColor from '../../../../common/TitlePageBiColor'
import ImageBorder from '../../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/structure_cabling.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Structure" } partRed={ "Cabling" } />
                            <p>
                                Because we know how disordered the electrical wiring can be, we offer you the service of a structured cabling system that will prevent you from having serious accidents. This service includes the grid of the cabling, the installation of it and the posteriors check-ups among certain amounts of time, which will be according to the magnitude of the work you've needed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;