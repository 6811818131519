import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/specialty_voltage_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "SV Electrical Contractors" } partRed={ "for Specialty Voltages" } />
                            <p>
                                To ensure the safety and quality of our work, the electrical contractors we hire for specialty voltages are the most specialized ones. Their extensive experience on a wide range of specialty voltages, gives us confidence to count with them to maximize your energy efficiency and the correct functioning of your equipment.
                            </p>
                            <TitlePageBiColor partBlack={ "Equipment Inspection and" } partRed={ "Testing" } />
                            <p>
                                For the correct inspection and testing of your equipment, we count with industrial electrical contractors that implement the National Electrical Code to ensure your safety. They will provide you with a detailed assessment of your equipment voltage requirements and will perform all the inspections they consider necessary –including run the tests required to isolate inefficiencies and troubleshoot issues.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "High Voltage" } partRed={ "Services" } />
                            <p>
                                We know that, for safe high voltage services, you require the most qualified professionals. That's why we count with only those that can ensure us that will perform their work ensuring its safety and proper installation. Also, their large experience on several ranges of this area improves the quality of the solutions they offer to you.
                            </p>
                            <TitlePageBiColor partBlack={ "Voltage" } partRed={ "Optimization" } />
                            <p>
                                The voltage optimization service we provide you adjusts your equipment by setting it to minimum specifications to help you achieve a more efficient consumption of power, without compromising the productivity of your company. Our industrial electrical technicians will save energy for your company in the most professional and secure way.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageBorder src="images/services/specialty_voltage_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;