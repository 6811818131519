import React from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
/* global google */
const LocationMap = () => {
    return (
        <Map
        google={ google }
        zoom={16}
        initialCenter={{ lat: 36.114943, lng: -80.050644 }}
        >
            <Marker
            title={'SV Electrical Contractors.'}
            name={'SVEC'}
            position={{lat: 36.114943, lng: -80.050644}} />
        </Map>
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDbnxCcoKXBHm0dhKQaBHl3R4WJDV9MJBk'
})(LocationMap)