import { type as changeNavSelected } from '../actions/changeNavSelected'
import { type as changeIndexAccordion } from '../actions/changeIndexAccordion'
import { type as login } from '../actions/login'
// import { type as searchText } from '../actions/searchText'
import { navs, navIndustry } from '../../components/common/Constants'
// import TextToSearch from '../../components/common/TextToSearch'

const defaultState = {
    menuOptions: navs.filter(option => option.display),
    menuOptionsMobile: navs.reduce((acc,cur,index) => {
        acc.push(cur)
        if(index === 1) { acc.push(navIndustry) }
        return acc
    },[]),
    indexAccordion: 0,
    loggedIn: true,
    searchResults: [],
    navSelected: 0
}

function reducer(state = defaultState, { type, payload }) {
    switch (type) {
        case changeNavSelected: {
            let menuOptions = state.menuOptions.map((menuOption,i) => {
                menuOption.active = i === payload ? true : false
                return menuOption
            })
            return { ...state , menuOptions, navSelected: payload  }
        }
        case changeIndexAccordion: {
            if(payload || payload === 0){
                return { ...state ,  indexAccordion: payload }
            }
            return state
        }
        case login: {
            return { ...state, loggedIn: payload }
        }
        // case searchText: {
        //     let newArray = []
        //     if( payload && payload !== '') {
        //         const Reg = new RegExp('\\b' + payload + '\\b','gi')
        //         const arrayContent = TextToSearch()
        //         newArray = arrayContent.filter( item => {
        //             return Reg.test(item.content)
        //         } )
        //     }
        //     return { ...state, searchResults: newArray }
        // }
        default:
            return state
    }
}

export default reducer