import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import changeNavSelected from '../../../redux/actions/changeNavSelected'
import DzetItem from './DzetItem'
import Form from './Form';
import MapZone from './MapZone';
import PageBanner from '../../common/PageBanner'

const Contact = props => {

    useEffect(() => {
        props.changeNavSelected(7)
    }, [])

    return(
        <>
        <PageBanner title='Contact' img={{ name: 'commonBanner', type: 'jpg' }}></PageBanner>
        <div className="section-full content-inner bg-white">
			<div className="container">
                <div className='row'></div>
                <div className="row dzseth2">
                    <DzetItem icon="ti-location-pin" title="Address" content="1031 E Mountain St. Kernersville, NC  27284"/>
                    <DzetItem icon="ti-email" title="Email" content="bmendiola@svecnc.com"/>
                    <DzetItem icon="ti-mobile" title ="Phone" content="(336) 343-6823	"/>
                    {/* <DzetItem icon="ti-printer" title="Fax" content="+61 3 8376 6284 / +23 123 456 7890"/> */}
                </div>
                <div className="row">
                    <Form/>
                    <MapZone/>
                </div>
            </div>
        </div>
        </>
    )
}

const mapStateToProps = state => { return state }
const mapDispatchToProps = { changeNavSelected }

export default connect(mapStateToProps,mapDispatchToProps)(Contact)