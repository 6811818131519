import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'
import SectionBlue from '../../../common/SectionBlue'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    const section = {
        title: 'Our Services Include',
        list: [
            { content: 'Mechanical Construction & Renovation', icon: 'flaticon-factory' },
            { content: 'Mechanical System Maintenance', icon: 'flaticon-factory-1' },
            { content: '24/7 Emergency Service', icon: 'flaticon-factory' }
        ]
    }
    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInLeft" { ...propForEffects }>
                            <ImageBorder src="images/services/mechanical_services.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <TitlePageBiColor partBlack={ "Equipment" } partRed={ "Upgrades" } />
                            <p>
                                For the application of the upgrades we must make in your mechanical equipment, we count with a staff that specifically studies which improvements on your machinery are truly worthy of doing. Not all of the possible improvements that are circling around the market are improvements your company can take a full advantage of.
                            </p>
                            <p>
                                Because we consider our crew of experts as a crucial part of our company, our staff also is aware of how often their training should be renewed or if they need new qualifications. We want to offer the best of the best to our industrial and commercial clients.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "New Equipment" } partRed={ "Installations" } />
                                <p>
                                    The service we offer you of installation of new equipment consists of the testing, pre-commissioning and setting up of it by the following of the manufacturer's instructions, the standard industrial practices and the relevant codes and standards. Thanks to the coordination we produce between our mechanical work and our electrical/instrumentation work, we can provide you with the most complete service in matters of safety. Last but not least, we are perfectly able to also provide you with labor, tools, equipment, materials, supplies and supervision in case you count with lack of some, or all, of them.
                                </p>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageBorder src="images/services/mechanical_services_2.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
            <SectionBlue { ...section } />
        </div>
    )
}

export default Content;