import React from 'react'
import TitlePageBiColor from '../../../../common/TitlePageBiColor'
import ImageBorder from '../../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/fire_alarm.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Fire" } partRed={ "Alarm" } />
                            <p>
                            The fire alarm device is a mechanism that has saved millions of lifes. Thanks to its sensor that spreads water when it detects smoke, the flame gets controlled until the firefighters and the police come by. What makes our device more efficient is that our system of communication counts with a quicker way to alert the authorities that something is not okay, securing the speed of the incoming help.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className= "col-sm-12 col-md-12 col-lg-12 wow fadeInRight" { ...propForEffects }>
                        <ImageBorder src="images/services/fire_alarm_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;