import React from 'react'
import {Link} from 'react-router-dom';
import ScrollTopFunction from '../../../common/ScrollTopFunction'

export const Content = () => {
  let lvss = [{
    id:"faq1",
    dataName:"01",
    icon:"fas fa-user-lock fa-4x",
    path:"access-control",
    title:"Access Control",
    content:"For access control, we offer you a device provided with the ultimate trustable security technology on the market. This device will be managed only after you enter your secret password."
},{
  id:"faq2",
  dataName:"02",
  icon:"fas fa-ethernet fa-4x",
  path:"fiber-optic",
  title:"Fiber Optics",
  content:"In this communication era, we know that fiber optics play a fundamental role. Adding them to our company brought us the sapience and the experience to be able to install them wherever you need to."
},{
  id:"faq3",
  dataName:"03",
  icon:"fas fa-bell fa-4x",
  path:"fire-alarm",
  title:"FireAlarm",
  content:"The fire alarm mechanism we provide counts with multiple hoses that will activate if the smoke sensor detects danger, and direct connection to the nearest central police department, the fire station and, if you want, people of your trust."
},{
  id:"faq4",
  dataName:"04",
  icon:"fas fa-tools fa-4x",
  path:"instrumentation-and-start-up",
  title:"Instrumentation and Start Up",
  content:"When you have a project in mind, you need a sequence of instructions to make that project developable. That's where we can help you, because our instrumentation and startup service it's designed to increase the success of your plan, by making it possible."
},{
  id:"faq5",
  dataName:"05",
  icon:"fas fa-sort-amount-up-alt fa-4x",
  path:"structure-cabling",
  title:"Structure Cabling",
  content:"We offer you the service of a structured cabling system that includes the grid of the cabling, the installation of it, and the posteriors check-ups among certain amounts of time, which will be according to the magnitude of the work you've needed."
},{
  id:"faq6",
  dataName:"06",
  icon:"fas fa-tty fa-4x",
  path:"telecommunications",
  title:"Telecommunications",
  content:"For the telecommunications you need everyday for your home, your work or simply for yourself, we count with a fiber optics service that will simplify your internet network by making it faster."
},{
  id:"faq7",
  dataName:"07",
  icon: "fas fa-tasks fa-4x",
  path:"testing-services",
  title:"Testing Services",
  content:"Our meticulous and periodic testing services guarantee you the quality of our job, and the safety of it, that we ensure by doesnt losing sight of any part of the production process."
}]
  return (
    <div className="bg-white tabs-box-area">
			<div className="section-full bg-white content-inner">
				<div className="container">
          <div className="row">
						<div className="col-lg-12">
							{/* <div className="sort-title clearfix text-center">
							</div> */}
              <div className="row mt-3">
              {
                lvss.map((item, key)=>{
                  return (
                    <div key={key} className="col-lg-6 col-md-6 service-box style3">
                      <div className="icon-bx-wraper" data-name={item.dataName}>
                          <Link to={item.path} className="icon-cell" onClick={ () => { ScrollTopFunction() } }><i className={item.icon}></i> <h2 className="dlab-tilte">{item.title}</h2> </Link>
                        <div className="icon-content">
                          <p className="text-justify">{item.content}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
                }
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
  )
}

export default Content;