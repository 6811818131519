import React from 'react'

const ListBox = ({ list }) => {
    return (
        <div className="frame-sr-list-area">
            <div className="row">
                <div className="col-lg-12">
                    <ul className="service-list2">
                    {
                        list.map((item,key) => {
                            if(item.title) {
                                return(
                                    <li className="text-justify" key={ key }>
                                        <p className="m-b5">
                                            <b>{ item.title + ' ' }</b>{ item.content }
                                        </p>
                                    </li>
                                )
                            } else {
                                return(
                                    <li className="text-justify" key={ key }>
                                        { item.content }
                                    </li>
                                )
                            }
                        })
                    }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ListBox
