import React from 'react'

const TitlePageBiColor = ({ partBlack, partRed }) => {
    return (
        <div className="m-b15">
            <h4 className="m-b0">{ partBlack } <span className="text-primary">{ partRed }</span></h4>
            <div className="dlab-separator bg-primary m-b0"></div>
        </div>
    )
}

export default TitlePageBiColor
