import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'
import ListBox from '../../../common/ListBox'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list2 = [
        { content: 'Office Building & Office Parks', },
        { content: 'Restaurants' },
        { content: 'Retail Establishments' },
        { content: 'Shopping Centers and Malls' },
        { content: 'Churches, Synagogues and Temples' },
        { content: 'Many More!' }
    ]
    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <ImageBorder src="images/services/commercial_electrical.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "Expert Commercial" } partRed={ "Electrician" } />
                                <p>
                                    For the quality and the safety of the diverse electrical services we offer, we have the best experts of commercial electricity on the market. With them in our crew, we have  highly skilled professionals that will listen to you carefully, and will solve your problems in the most efficient way.
                                </p>
                                <TitlePageBiColor partBlack={ "Equipment" } partRed={ "Upgrades" } />
                                <p>
                                    As our name indicates, we are a group of electrical contractors, and to achieve our goal to be the best ones on the market we must be in constant innovation. That’s the reason we are always looking forward for commercial electrical equipment	 upgrades that will flatten us the ground to achieve your satisfaction through innovative technological tools.<br/>
                                    When you start to perceive economical losses because of the wrong functioning of your equipment, you will certainly need the expert solution we can provide you with the most complete upgrading of them. Our purpose is to increase the quality of your production and to lower your difficulties as minimum as possible, through our professional solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "New Equipment" } partRed={ "Installations" } />
                                <p>
                                    We know that the technology keeps moving forward, and upgrading your equipment may not be enough to achieve your business goals. For that reason, we count with electricians in our crew who, thanks to their experience, are able to install safely and professionally any new equipment you acquire for your company.
                                </p>
                                <TitlePageBiColor partBlack={ "Commercial Electric" } partRed={ "Maintenance" } />
                                <p>
                                We count with a highly capacitated crew, and the best machinery on the market, to provide you with the greatest maintenance of commercial electrical equipment. Our goal is to win your trust through our services, and let those speak louder than our explanation of how great it is for you to hire us.
                                </p>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <ListBox list={ list2 }/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;