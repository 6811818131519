import React from 'react'

const SectionBlue = ({ title, list }) => {
    return (
        <div className="section-full content-inner-1 overlay-blue about-8-service bg-img-fix p-t60 sectionBlue" style={{ marginBottom: '30px', marginTop: '100px' }}>
            <div className="container">
                <div className="section-head text-white text-center">
                    <h2 className="title-box m-tb0 max-w800 m-auto">{ title } <span className="bg-primary"></span></h2>
                </div>
            </div>
            <div className="container">
                <div className="row text-white">
                    {
                        list.map((item,key) => {
                            return(
                                <div key={ key } className="col-lg-4 col-md-4 m-b30">
                                    <div className="icon-bx-wraper bx-style-1 p-a30 center" style={{ minHeight: '260px' }}>
                                        <div className="icon-lg text-white m-b20"> <i className={item.icon }></i></div>
                                        <div className="icon-content">
                                            { item.name ? <h5 className="dlab-tilte text-uppercase">{ item.name }</h5> : '' }
                                            <p>{ item.content }</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default SectionBlue
