import React from 'react'
import TitlePageBiColor from '../../common/TitlePageBiColor'
import ImageBorder from '../../common/ImageBorder'
import ListBox from '../../common/ListBox'
export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list1 = [
        { content: 'Office Building & Office Parks'},
        { content: 'Restaurants' },
        { content: 'Retail Establishments' },
        { content: 'Shopping Centers and Malls'}
    ]
    let list2 = [
        { content: 'Churches, Synagogues and Temples'},
        { content: 'Medical and Dental Offices'},
        { content: 'Clubs'}
    ]
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/about_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Welcome to" } partRed={ "SV Electrical Contractors" } />
                            <p>
                            SV Electrical Contractors is a full-service electrical contractor providing construction services for commercial & industrial clients throughout North and South Carolina, our company is specialized in every area having technicians with more then 15 years of experience.
                            </p>
                            {/* <TitlePageBiColor partBlack={ "Lighting" } partRed={ "Audits" } /> */}
                            <p>
                            Ensuring the safety of our associates is a vital aspect, SV electrical Contractor has a strong commitment to safety is built into the structure of our organization, we believe in building trust through our performance.
                            </p>
                            <p>
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "What makes" } partRed={ "us different" } />
                            <p>
                            With the years SV Electrical Contractors has grown thanks to our excellent and professional performance in every project, because of the end of every project quality is our main goal.
                            </p>
                            <TitlePageBiColor partBlack={ "Experienced Commercial" } partRed={ "Electricians for:" } />
                            <div className="row">
                                <div className= "col-sm-6 col-md-6">
                                    <ListBox list={ list1 }/>
                                </div>
                                <div className= "col-sm-6 col-md-6">
                                    <ListBox list={ list2 }/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/about_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;