import React from 'react'
import TitlePageBiColor from '../../../../common/TitlePageBiColor'
import ImageBorder from '../../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/access_control.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Access" } partRed={ "Control" } />
                            <p>
                                The access control you need to all of the areas that you consider that are restricted, will be completely covered up by our ultimate technology system in matters of this type of security. We count with the most perfected automate system, that will automatically deny the pass to the person that couldn't comply with our very high security parameters. Your satisfaction is our duty.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;