import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/phone_wiring_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Phone Wiring" } partRed={ "& Networking" } />
                            <p>
                                The phone wiring we provide includes cables that can be used for enterprise network connectivity. With this service, we will improve your communications in the most complete way possible to suit your needs. Our crew will do its job effectively and professionally in order for your network to be as reliable as you need it.
                            </p>
                            <TitlePageBiColor partBlack={ "Telephone Troubleshooting" } partRed={ "and Repairs" } />
                            <p>
                                Does your line stutter or have an irregular or dead dial tone? Can’t you receive or perform any calls? Don’t worry about it. Our technicians are perfectly able to repair any failures your telephone infrastructure may suffer. Call us and our staff will solve the issue as soon as possible
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Telephone" } partRed={ "Installation" } />
                            <p>
                                Our staff is trained to carry out all the necessary work to put in place an industrial telephone installation. Following all the current technical standards, our experts will be in charge of everything: from the wiring and the telephone switchboard installation to the setup of the digital network infrastructure for your whole business. Evidently, our technicians also will handle the setup of the telephone sockets and devices.
                            </p>
                            <p>
                                We can guarantee a high-quality industrial telephone installation that will allow your employees to do their job efficiently and your business to grow and succeed.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/phone_wiring_2.jpg"/>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/phone_wiring_3.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Structured" } partRed={ "Cabling" } />
                            <p>
                                The structured cabling is a solution that will help your network to handle the data traffic your equipment and employees need to do their job effectively. The main feature of a structured cabling system is its scalability: nevertheless how many devices you connect to your business network, there will be enough bandwidth in order for your employees to succeed.
                            </p>
                            <TitlePageBiColor partBlack={ "Qualified Technicians" } partRed={ "for Any Project" } />
                            <p>
                                We know how important it is for you to find a company that can mold into your specific needs, requirements and desires, and not the other way around. Our qualified technicians are fully capable to fulfill your expectations for any project, in the most complete, innovative and secure way possible.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;