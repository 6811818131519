import React, { useState } from 'react'

const SearchPopUp = () => {
    const { search, setSearch } = useState('')

    const handlerInputChange = event => {
        setSearch(event.target.value)
    }

    return (
        <div className='dlab-quik-search'>
            <form action='#'>
                <input name='search' value={ search } type='text' className='form-control' placeholder='Type to search' onChange={ handlerInputChange } />
                <span id='quik-search-remove'><i className='ti-close'></i></span>
            </form>
        </div>
    )
}

export default SearchPopUp
