import React from 'react'

export const Content = () => {
  return (
    <div className="container mt-5">
        <div className="row">
            <div className="col-lg-6 col-md-12">
                <img className="radius-sm w-100 mb-5" src="images/services/services_area.jpg" alt=""/>
            </div>
            <div className="col-lg-6 col-md-12">
                <p className="text-justify">
                  SV Electrical Contractors performs a full range of electrical, commercial and mechanical contractor services. Our main service area is in the Northeast, concentrating in Pennsylvania, New Jersey, Delaware, New York, Connecticut, Maryland, and Washington, DC. Some of our large industrial projects have also required our team to travel across the country and work on various client locations.
                </p>
                <p className="text-justify">
                  For customers with special, time-sensitive projects, we have done work in Greenwich CT, Elkhart IN, Grand Rapids MI, South Carolina, North Carolina, Atlanta GA, Mississippi, Virginia, California and even in Toronto Canada. Many of these special projects have been in the food industry and mold injection industry. If your business will benefit from our services, we are happy to travel to your job site. We typically qualify a project on several important parameters including, but not limited to: project scope, complexity, order size and cost, the challenge it offers our team, opportunity to partner with other big players involved in the project, and so on.
                </p>
            </div>
        </div>
      </div>
  )
}

export default Content;