import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/harmonic_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Harmonic" } partRed={ "Mitigation" } />
                            <p>
                                To provide you with the harmonic mitigation service, we identify and isolate their source through the monitoring of the power and the data acquisition systems. Our purpose is to eliminate the power system anomalies that come with them, which will directly lead into an increase of the production and efficiency of your company.
                            </p>
                            <TitlePageBiColor partBlack={ "What is Harmonic" } partRed={ "Mitigation?" } />
                            <p>
                                The harmonic mitigation we provide interrupts the consumption of energy, until we determine that the harmonics of your electrical system are in safe levels. We know that power quality issues will directly lead to problems, such as voltage sags, that will low your efficiency and productivity. That’s why we have the best equipment and professionals to deliver the exact secure upgrade you need.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Electricians for Diagnosing" } partRed={ "Harmonics" } />
                            <p>
                                Our company has the most competent electricians in matters of harmonics diagnosis, to provide you with the most complete service. The periodic courses and capacitations we impart to them to increase their work efficiency, plus the constant machinery upgrades we do, makes us the safest option to deliver to you the exact type of solution you need.
                            </p>
                            <TitlePageBiColor partBlack={ "Harmonic Mitigation" } partRed={ "Services" } />
                            <p>
                                Our harmonic mitigation services are the most complete ones you can possibly find, because we provide solutions for all the kinds of harmonics-related issues. We have the best trained electricians to provide you with the exact solution you need, and, thanks to our highest technology equipment, we ensure that the result of our work will be safe and enduring in time.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageBorder src="images/services/harmonic_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;