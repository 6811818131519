import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <ImageBorder src="images/services/porject_coordination_1.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "Efficient Planning" } partRed={ "and Scheduling" } />
                                <p>
                                    We know that sometimes you can be overwhelmed by work, and that you may need to rely a part of it on someone else. That is why we count with the most complete efficient planning and scheduling program, whose principal benefit for you is the correct management of your clients projects and to carry them forward to their accomplishment, keeping its cronogram and its costs under control. The planning we develop let us primarily deal with the selection of the appropriate policies and procedures in order to achieve the objective of your project, and our scheduling system converts your project action plans for scope, time cost and quality into an operating timetable.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "Surveying and Reporting" } partRed={ "for Time and Cost Control" } />
                                <p>
                                    Our experience on matters of surveying and reporting for time and cost control tells us that it's a very common thing that the companies exceed the budget and the schedule previously established. That's why we count with a special area composed by the most trained specialists on matters of analysis and preparation of reports about cost control and of how efficient and productive is your business use of time and resources. They will develop an important amount of lists for you, that will include diverse options to improve the productivity of your company in the way of your best consideration.
                                </p>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageBorder src="images/services/porject_coordination_2.jpg"/>
                            {/* <TitlePageBiColor partBlack={ "List Title" } partRed={ "Example" } /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;