import React from 'react'
import TitlePageBiColor from '../../common/TitlePageBiColor'
import ImageBorder from '../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/consulting/consulting.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Electrical" } partRed={ "Consulting" } />
                            <p>
                                For electrical consulting services, we count with a crew of the most expert professionals who we designate to search and spotlight areas that result in an impediment to save energy because of the amount of electricity that they waste. Our crew also will bring you the solution to cut off your energy consumption in a customized way and that will lead into an increase of your savings.
                            </p>
                            <p>
                                Because of the thorough research our employees make, they can identify the real root of the issue and, according to the magnitude of the problem, they will develop the exact type of effective and complete solution that your case requires.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;