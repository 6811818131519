import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'


export const Content = () => {
  let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
  return (
    <div className="container m-b60 customPage">
      <div className="section-full content-inner-1 about-bx">
        {/* // ------------------------------ROW----------------------------------- */}
        <div className="row mb-4">
          {/* // ------------------------------COL 1----------------------------------- */}
          <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
            <ImageBorder src="images/services/data_wiring_1.jpg"/>
          </div>
          {/* // ------------------------------COL 2----------------------------------- */}
          <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
            <div className="align-self-center">
              <TitlePageBiColor partBlack={ "Data Networking " } partRed={ "and Cabling Services" } />
              <p>
                A network is essential for every business and, for that reason, no business owner should take lightly the installation of this kind of infrastructure. Our qualified electricians are capable of designing a special solution that works for your business or industrial plant, and of cabling your network safely and neatly.
              </p>
              <TitlePageBiColor partBlack={ "Professional Data " } partRed={ "Networking Installation" } />
              <p>
                Your data network infrastructure is an asset that will enable your business to work efficiently. A professional electrician can prepare for you a custom plan and install the cabling in your office or industrial plant.  Our staff will ensure your network runs smoothly, it does not matter the size of your business.
              </p>
            </div>
          </div>
        </div>
        {/* // ------------------------------ROW----------------------------------- */}
        <div className="row mb-4">
          {/* // ------------------------------COL 1----------------------------------- */}
          <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
            <div className="align-self-center">
              <TitlePageBiColor partBlack={ "Structured" } partRed={ "Cabling" } />
              <p>
                Your employees and equipment require a network to be efficient and productive. Nowadays, your business relies more on the network because of technologies –such as Voip calling systems, laptops and tablets– present in the workplace. In consequence, it is vital your infrastructure provides enough bandwidth to the devices that will be connected.
              </p>
              <p>
                The structured cabling is a solution that will help your network to handle the data traffic your equipment and employees need. A structured cabling system is scalable and thus you will future-proof your business network: you can be sure that, when any new equipment is connected, there will be enough bandwidth to do its job successfully.
              </p>
            </div>
          </div>
          {/* // ------------------------------COL 2----------------------------------- */}
          <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
            <ImageBorder src="images/services/data_wiring_2.jpg"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content;