import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/conveyor_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Production Equipment" } partRed={ "Installation" } />
                            <p>
                                We know that the installation of production equipment must be performed with the newest technology and by the highest levels of quality and safety. To carry it out in the most complete way, we count with the most innovative tools, because our purpose is to win your trust through the well functioning of our work.
                            </p>
                            <TitlePageBiColor partBlack={ "Carousel and Conveyor Wiring and" } partRed={ "Installation" } />
                            <p>
                                For carousel and conveyor wiring and installation, we count with the most qualified industrial electricians to listen carefully to your requirements and needs, and meet your expectations. To achieve that, we will improve the output and productivity of your operations, we will update their layout and we will maximize their performance.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Equipment Upgrades and" } partRed={ "Expansions" } />
                            <p>
                                For equipment upgrades and expansions services, we count with industrial experts that will answer all of your questions, and will perform exactly what you require. Our success depends on how safe, efficient and economical it is the work we have carried out for you. That's why we are in constant modernization.
                            </p>
                            <TitlePageBiColor partBlack={ "Process" } partRed={ "Controls" } />
                            <p>
                                We know how important it is for a company to reduce their energy usage and to lower their utility costs, and how important the process controls are to achieve it. That's why we count on the most competent technicians to install for you a complete range of controls and programs that will fully automate your production and your material handling systems.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <ImageBorder src="images/services/conveyor_2.jpg"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;