import React from 'react'
import TitlePageBiColor from '../../common/TitlePageBiColor'
import ImageBorder from '../../common/ImageBorder'
import ListBox from '../../common/ListBox'
import SectionBlue from '../../common/SectionBlue'

export const Content = () => {
    const propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    const section = {
        title: 'Experienced Commercial Electricians',
        list: [
            { content: 'Breaker Box Wiring, installing and servicing breaker boxes.', icon: 'flaticon-factory' },
            { content: 'Data Wiring & Networking Data installation and networking for optimum reliability and performance.', icon: 'flaticon-factory-1' },
            { content: 'Electrical Outlet Installation Journeyman electrician for installing power outlets.', icon: 'flaticon-factory-1' },
            { content: 'Electrical Inspections Occupancy inspections, safety inspections and more.', icon: 'flaticon-factory'},
            { content: 'Electrical Wiring Installation of power systems, electronics, equipment, signage and more.', icon: 'flaticon-factory-1' },
            { content: 'GFCI Installation Ground Fault Circuit Interrupter installation for safety.', icon: 'flaticon-factory-1'}
        ]
    }
    let list2 = [
        { content: 'Office Building & Office Parks', },
        { content: 'Restaurants' },
        { content: 'Retail Establishments' },
        { content: 'Shopping Centers and Malls' },
        { content: 'Churches, Synagogues and Temples' },
        { content: 'Many More!' }
    ]
    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <ImageBorder src="images/services/commercial_electrical.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "Expert Commercial" } partRed={ "Electrician" } />
                                <p>
                                    A reliable electric system is key to the success of any business. Our qualified electricians will test, monitor and replace any part of the electrical system to fix faults and prevent power outages and more serious issues that may cause damage to your equipment and risk the life of your employees.
                                </p>
                                <TitlePageBiColor partBlack={ "Equipment" } partRed={ "Upgrades" } />
                                <p>
                                    Our experience on upgrading equipment, give us confidence to tell you and provide you with the most complete one you can possibly get. The technicians of our company are trained to proportion you assessment on all the details of your upgrade, and a personalized service to achieve the satisfaction of your requirements.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "New Equipment" } partRed={ "Installations" } />
                                <p>
                                    For installations of new equipment, we count with the most innovative equipment to provide you with safety for you and for everyone nearby. We know how significantly money you’ve spent on it, and we also know how our expert solutions can optimize your business more than you can imagine.
                                </p>
                                <TitlePageBiColor partBlack={ "Commercial Electric" } partRed={ "Maintenance" } />
                                <p>
                                    We count with the newest innovations and upgrades in our equipment, and constantly trained employees to guarantee you the excellence of our commercial electric maintenance.  Your safety and your satisfaction is our aspiration, and our medium to reach it is providing you with what you need, with what you expect and with what you deserve.
                                </p>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <ListBox list={ list2 }/>
                        </div>
                    </div>
                </div>
            </div>
            <SectionBlue { ...section } />
        </div>
    )
}

export default Content;