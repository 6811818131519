import React from 'react'
import TitlePageBiColor from '../../../../common/TitlePageBiColor'
import ImageBorder from '../../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }

    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/instrumentation_and_startup.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Instrumentation" } partRed={ "And Start Up" } />
                            <p>
                                One service we offer to make your projects possible is the capability to provide you, through instrumentation, with all the materials you need to start up to start your construction work as soon as possible. We also count with a group of professionals to guide you in all the aspects you may possibly need help.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;