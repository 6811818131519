import React from 'react'
import { Link } from 'react-router-dom';
import ScrollTopFunction from '../common/ScrollTopFunction'

const Slide = ({ slide }) => {

    const handleClick = () => {
		ScrollTopFunction()
	}

    const getlayerVideo = slide => {
        if(slide.type === 'video') {
            let mediaPath = 'media/video/' + slide.imgName + '.' + slide.format;
            return(
                <div className="rs-background-video-layer"
                    data-forcerewind="on"
                    data-volume="mute"
                    data-videowidth="100%"
                    data-videoheight="100%"
                    data-videomp4={ mediaPath }
                    data-videopreload="auto"
                    data-videoloop="loop"
                    data-aspectratio="16:9"
                    data-autoplay="true"
                    data-autoplayonlyfirsttime="false">
                </div>
            )
        } else {
            return('');
        }
    };

    const getlayer1 = () => {
        return(
            <div className="tp-caption tp-shape tp-shapewrapper ov-tp "
            id="slide-100-layer-1"
            data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
            data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
            data-width="full"
            data-height="full"
            data-whitespace="nowrap"
            data-type="shape"
            data-basealign="slide"
            data-responsive_offset="off"
            data-responsive="off"
            data-frames='[
                {"delay":10,"speed":1000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},
                {"delay":"wait","speed":1500,"frame":"999","to":"opacity:0;","ease":"Power4.easeIn"}
            ]'
            data-textalign="['inherit','inherit','inherit','inherit']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"
            style={{
                zIndex: 5,
                fontFamily: "'rubik', sans-serif"
            }}>
            </div>
        )
    };

    const getlayer2 = () => {
        return(
            <div className="tp-caption "
            id="slide-100-layer-2"
            data-x="['center','center','center','center']" data-hoffset="['-90','-300','0','0']"
            data-y="['middle','middle','middle','middle']" data-voffset="['-30','-55','-50','-70']"
            data-fontsize="['65','50','40','30']"
            data-lineheight="['75','60','50','40']"
            data-letterspacing="['3','2','2','2']"
            data-width="['1000','none','768','360']"
            data-height="none"
            data-whitespace="['normal','nowrap','normal','normal']"
            data-type="text"
            data-responsive_offset="off"
            data-responsive="off"
            data-frames='[
                {"delay":900,"speed":2000,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:1.1;sY:1.1;skX:0;skY:0;opacity:0;","color":"#000000","to":"o:1;","ease":"Power3.easeInOut"},
                {"delay":"wait","speed":500,"frame":"999","color":"#000000","to":"opacity:0;","ease":"nothing"}]'
            data-textalign="['left','left','center','center']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[10,10,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"
            style={{
                zIndex: '6',
                minWidth: '800px',
                maxWidth: '800px',
                fontWeight: '900',
                whiteSpace: 'normal',
                color: '#00030f',
                fontFamily: "'Roboto Condensed', sans-serif"
            }}>
                { slide.title }<br/>
                { slide.titlePart2 }
            </div>
        )
    };

    const getlayer3 = () => {
        return(
            <div className="tp-caption"
            id="slide-100-layer-3"
            data-x="['center','center','center','center']" data-hoffset="['-265','-170','0','0']"
            data-y="['middle','middle','middle','middle']" data-voffset="['100','55','50','30']"
            data-fontsize="['16','16','15','14']"
            data-lineheight="['30','30','25','22']"
            data-width="['630','550','500','300']"
            data-height="none"
            data-whitespace="normal"
            data-type="text"
            data-responsive_offset="off"
            data-responsive="off"
            data-frames='[
                {"delay":900,"speed":2000,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:1.1;sY:1.1;skX:0;skY:0;opacity:0;","color":"#000000","to":"o:1;","ease":"Power3.easeInOut"},
                {"delay":"wait","speed":500,"frame":"999","color":"#000000","to":"opacity:0;","ease":"nothing"}
            ]'
            data-textalign="['left','left','center','center']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"
            style={{
                zIndex: '7',
                minWidth: '640px',
                maxWidth: '640px',
                fontSize: '18px',
                lineHeight: '30px',
                fontWeight: '400',
                color: '#ffffff',
                fontFamily: "'Poppins',sans-serif"
            }}>
                { slide.content }
            </div>
        )
    };

    const getlayer4 = () => {
        return(
            <Link className="tp-caption rev-btn tc-btnshadow tp-rs-menulink bg-primary"
            to="/about"
            id="slide-100-layer-4"
            data-x="['center','center','center','center']" data-hoffset="['-515','-375','-90','-75']"
            data-y="['middle','middle','middle','middle']" data-voffset="['190','140','130','120']"
            data-lineheight="['18','18','18','18']"
            data-whitespace="nowrap"
            data-type="button"
            data-actions=''
            data-responsive_offset="off"
            data-responsive="off"
            data-frames='[
                {"delay":900,"speed":2000,"frame":"0","from":"x:-50px;z:0;rX:0;rY:0;rZ:0;sX:1.1;sY:1.1;skX:0;skY:0;opacity:0;fbr:100;","bgcolor":"#000000","to":"o:1;fbr:100;","ease":"Power3.easeInOut"},
                {"delay":"wait","speed":500,"frame":"999","bgcolor":"#000000","to":"opacity:0;fbr:100;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"150","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;fbr:90%;","style":"c:rgba(255,255,255,1);"
            }]'
            data-textalign="['center','center','center','center']"
            data-paddingtop="[15,15,15,10]"
            data-paddingright="[30,30,30,20]"
            data-paddingbottom="[15,15,15,10]"
            data-paddingleft="[30,30,30,20]"
            style={{
                zIndex: '8',
                letterSpacing: '2px',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                fontWeight: '600',
                color: 'rgba(255,255,255,1)',
                textTransform: 'uppercase'
            }}>
                About Us
            </Link>
        )
    };

    const getlayer5 = () => {
        return(
            <Link className="tp-caption rev-btn tc-btnshadow tp-rs-menulink bg-primary"
            to="/services"
            id="slide-100-layer-5"
            data-x="['center','center','center','center']" data-hoffset="['-360','-220','60','50']"
            data-y="['middle','middle','middle','middle']" data-voffset="['190','140','130','120']"
            data-lineheight="['18','18','18','18']"
            data-whitespace="nowrap"
            data-type="button"
            data-actions=''
            data-responsive_offset="off"
            data-responsive="off"
            data-frames='[
                {"delay":900,"speed":2000,"frame":"0","from":"x:-50px;z:0;rX:0;rY:0;rZ:0;sX:1.1;sY:1.1;skX:0;skY:0;opacity:0;fbr:100;","bgcolor":"#000000","to":"o:1;fbr:100;","ease":"Power3.easeInOut"},
                {"delay":"wait","speed":500,"frame":"999","bgcolor":"#000000","to":"opacity:0;fbr:100;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"150","ease":"Power1.easeInOut","to":"o:1;rX:0;rY:0;rZ:0;z:0;fbr:90%;","style":"c:rgba(255,255,255,1);"
            }]'
            data-textalign="['center','center','center','center']"
            data-paddingtop="[15,15,15,10]"
            data-paddingright="[30,30,30,20]"
            data-paddingbottom="[15,15,15,10]"
            data-paddingleft="[30,30,30,20]"
            style={{
                zIndex: '8',
                letterSpacing: '2px',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                fontWeight: '600',
                color: 'rgba(255,255,255,1)',
                textTransform: 'uppercase'
            }}>
                Services
            </Link>
        )
    };

    const getlayer6 = () => {
        return(
            <div className="tp-caption tp-resizeme rs-parallaxlevel-1"
            id="slide-100-layer-6"
            data-x="['left','left','left','left']"
            data-hoffset="['-310','40','-40','40']"
            data-y="['bottom','bottom','bottom','bottom']"
            data-voffset="['-50','-50','-50','-50']"
            data-fontsize="['280','200','150','100']"
            data-lineheight="['240','240','240','150']"
            data-width="none"
            data-height="none"
            data-whitespace="nowrap"
            data-type="text"
            data-responsive_offset="on"
            data-frames='[
                {"delay":1300,"speed":3000,"frame":"0","from":"sX:5;opacity:0;fb:90px;","to":"o:5;fb:0;","ease":"Power3.easeInOut"},
                {"delay":"wait","speed":100,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}
            ]'
            data-textalign="['left','center','center','center']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"
            style={{
                zIndex: 6,
                whiteSpace: 'nowrap',
                fontSize: '180px',
                color:'rgba(255,255,255,0.05)',
                lineHeight: '240px',
                fontWeight: '700',
                fontFamily: "'Poppins', sans-serif"
            }}>
                <div className="rs-looped rs-wave"  data-speed="3" data-angle="0" data-radius="2px" data-origin="50% 50%">{ slide.title }</div>
            </div>
        )
    }

    const getlayer7 = () => {
        return(
            <div className="tp-caption rev-btn rs-parallaxlevel-1"
            id="slide-100-layer-7"
            data-x="['left','left','left','left']" data-hoffset="['-10','-40','-30','-20']"
            data-y="['top','top','top','top']" data-voffset="['620','610','610','470']"
            data-fontsize="['16','16','16','16']"
            data-lineheight="['20','20','20','20']"
            data-width="['none','none','none','320']"
            data-height="none"
            data-whitespace="['nowrap','nowrap','nowrap','normal']"
            data-type="text"
            data-responsive_offset="on"
            data-frames='[
                {"delay":250,"speed":5000,"frame":"0","from":"y:100px;rZ:15deg;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},
                {"delay":"wait","speed":300,"frame":"1999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}
            ]'
            data-textalign="['inherit','inherit','inherit','center']"
            data-paddingtop="[0,0,0,0]"
            data-paddingright="[0,0,0,0]"
            data-paddingbottom="[0,0,0,0]"
            data-paddingleft="[0,0,0,0]"
            style={{
                zIndex: '12',
                fontSize: '16px'
            }}>
                <Link to='/' onClick={ () => handleClick() } className="site-button btnhover19 button-md outline outline-2 white bg-primary">Our Services</Link>
            </div>
            // <Link to='/capabilities' onClick={ () => handleClick() } className="tp-caption TM-Button-flat-01 rev-btn bg-primary rs-parallaxlevel-1"></Link>
        )
    };

    return(
        <section>
            { getlayer1() }
            { getlayer2() }
            { getlayer3() }
            { getlayer4() }
            { getlayer5() }
            {/* { getlayer6() }
            { getlayer7() } */}
            {/* <!-- [ for video ]
            { getlayerVideo(slide) }--> */}
        </section>
    )
}

export default Slide