import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/lighting_design_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Lighting Design" } partRed={ "Services" } />
                            <p>
                                For lighting design services, we are willing to listen to your idea and carry it out to fulfill your expectations. We are fully aware that the illumination of both indoor and outdoor places of your business can change the entire atmosphere of those spaces and that's why our purpose is to deliver exactly what you have in mind.
                            </p>
                            <TitlePageBiColor partBlack={ "Retrofitting" } partRed={ "Lights" } />
                            <p>
                                Improving your lights on the retrofitting means the improvement of their efficiency by upgrading your light fixtures or lamps. We are completely capable of performing that job, delivering your exact idea thanks to our professionals and modern equipment. Our purpose is to exceed your expectations and earn your trust.
                            </p>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                        <TitlePageBiColor partBlack={ "Complete Energy Efficient" } partRed={ "Lighting Solutions" } />
                            <p>
                                We can provide your business with the most complete efficient lighting solutions. Our company’s very capable professionals have a lot of years of experience in this area and the most innovative equipment. Our electricians can and will implement the best lighting solution for your business. This solution will be energy-efficient in order to help your business save money in your utility bill.
                            </p>
                            <p>
                                With an extensive array of very pleased clients, we have the most prestigious and efficient suppliers that there are in our industry. We hope the lighting solution our electricians will implement in your business will exceed all your expectations.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/lighting_design_3.jpg"/>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/lighting_design_2.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Lighting Design" } partRed={ "for Unique Atmospheres" } />
                            <p>
                                We know that unique atmospheres require unique designs, especially in lighting matters. That's why we have the best team of professionals and suppliers to listen to your idea and deliver it exactly how you wanted to. The balance between safety, beauty and efficiency is exactly what you deserve, and it's exactly what we offer you.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;