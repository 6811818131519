import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'
import SectionBlue from '../../../common/SectionBlue'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    const section = {
        title: "The PLC's we produce include the following services",
        list: [
            { content: 'Technology of simple implementation and quick execution.', icon: 'flaticon-factory' },
            { content: 'Automation of the process of your project, speeding up the times of it and reducing its costs.', icon: 'flaticon-factory-1' },
            { content: 'Low maintenance, thanks to its high quality.', icon: 'flaticon-factory-1' }
        ]
    }
    return (
        <div className="content-block">
            <div className="container m-b60 customPage">
                <div className="section-full content-inner-1 about-bx">
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <ImageBorder src="images/services/plc_1.jpg"/>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "Programmable Logic" } partRed={ "Controllers" } />
                                <p>
                                    Thanks to our periodic machinery upgrades, the programmable logic controllers our professionals constantly produce have the newest technology to detect the diversal types of processes signals, to receive configurations from the operators and to elaborate and send orders according to your previous programmation. We also have many types of PLC to provide you with the exact one you need.
                                </p>
                                <TitlePageBiColor partBlack={ "PLC" } partRed={ "Services" } />
                                <p>
                                    Customized installation, to ensure that the final result will satisfy you on every aspect.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* // ------------------------------ROW----------------------------------- */}
                    <div className="row mb-4">
                        {/* // ------------------------------COL 1----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                            <div className="align-self-center">
                                <TitlePageBiColor partBlack={ "PLC" } partRed={ "Functionality" } />
                                <p>
                                    The PLC functionality consists in a meticulous series of technologies that allow you to use the cables of your electrical installation to deliver specific information through the internet from one place to another. This will assist you in the establishment of firm and important communications between two different areas of your company.
                                </p>
                                <TitlePageBiColor partBlack={ "Qualified Electricians for Complete" } partRed={ "Services" } />
                                <p>
                                    Our company has the most qualified electricians of the market, to increase the quality of our services. Our staff is composed of the best trained professionals who can fulfill our requirements in the most complete way possible and whose work will exceed all of your expectations.
                                </p>
                            </div>
                        </div>
                        {/* // ------------------------------COL 2----------------------------------- */}
                        <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                            <ImageBorder src="images/services/plc_2.jpg"/>
                        </div>
                    </div>
                </div>
            </div>
            <SectionBlue { ...section } />
        </div>
    )
}

export default Content;