import React from 'react'

const TitlePageBiColor = ({ src }) => {
    return (
        <div className="about-box">
            <div className="dlab-img-effect zoom">
                <img className='imgCustom' src={ src } alt=""/>
            </div>
        </div>
    )
}

export default TitlePageBiColor
