import React from 'react'
import TitlePageBiColor from '../../../common/TitlePageBiColor'
import ImageBorder from '../../../common/ImageBorder'
import ListBox from '../../../common/ListBox'

export const Content = () => {
    let propForEffects = { 'data-wow-duration': '2s', 'data-wow-delay': '0.3s' }
    let list = [{
        title: 'Additional lighting, power strips and other power supplies:',
        content: 'this will improve the comfort of your employees, and of your clients.'
    }, {
        title: 'Structured cabling:',
        content: 'it will help your network to handle the data traffic your equipment and employees need.'
    }, {
        title: 'Fiber optics:',
        content: 'to speed up and secure your telecommunications. '
    }]
    return (
        <div className="container m-b60 customPage">
            <div className="section-full content-inner-1 about-bx">
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/office_wiring_1.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Office" } partRed={ "Wiring" } />
                            <p>
                                The solutions we offer you to your office wiring are:
                            </p>
                            <ListBox list={ list }/>
                        </div>
                    </div>
                </div>
                {/* // ------------------------------ROW----------------------------------- */}
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Office Electric Services" } partRed={ "by Skilled Contractors" } />
                            <p>
                                We know that electrical faults in your business may lead directly to hazards such as fires or to problems such as causing damages in office equipment and stopping the effective communication with clients and coworkers. That’s why we provide you with the service of quick electrical solutions at the minute you call us.
                            </p>
                            <TitlePageBiColor partBlack={ "Electric Rewiring Solutions" } partRed={ "for Office Spaces" } />
                            <p>
                                Damaged wires at your office can lead to dark hallways, hobble the break room appliances, halt work, among others possible damages. To avoid you the loss of money, we offer you electric rewiring solutions for your entire office spaces. The professionals of our company are completely capacitated to rewire your office when you need it.
                            </p>
                        </div>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInRight m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/office_wiring_2.jpg"/>
                    </div>
                </div>
                <div className="row mb-4">
                    {/* // ------------------------------COL 1----------------------------------- */}
                    <div className="col-sm-12 col-md-6 col-lg-6 wow fadeInLeft m-b30" { ...propForEffects }>
                        <ImageBorder src="images/services/office_wiring_3.jpg"/>
                    </div>
                    {/* // ------------------------------COL 2----------------------------------- */}
                    <div className= "col-sm-12 col-md-6 col-lg-6 wow fadeInRight" { ...propForEffects }>
                        <div className="align-self-center">
                            <TitlePageBiColor partBlack={ "Office equipment" } partRed={ "and Network Upgrades" } />
                            <p>
                                If your actual network does not cover your business needs, then our staff can help you. Our team of professional electricians has the training and experience to upgrade your network infrastructure. We will ensure your network meets your business requirements in order to help your business grow and succeed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Content;