import React from 'react'
import ContactUs from './ContactUs'
import Copyright from './Copyright';
import Company from './Company';
import UserLink from './UserLink';
import NavInfo from './NavInfo';
import SectionInformationFooter from '../common/SectionInformationFooter'

const Footer = () => {
    return (
        <>
            <SectionInformationFooter/>
            <footer className='site-footer' id='footer'>
                <div className='footer-top' style={{ backgroundImage:'url(images/background/bg2.png)', backgroundSize:' contain' }}>
                    <div className='container'>
                        <div className='row'>
                            <Company/>
                            <UserLink/>
                            <ContactUs/>
                            <NavInfo/>
                        </div>
                    </div>
                </div>
                <Copyright/>
            </footer>
        </>
    )
}

export default Footer
