import React from 'react'
import { cardsFormHome } from '../common/Constants'
import { Link } from 'react-router-dom'
import ScrollTopFunction from '../common/ScrollTopFunction'

const Cards = () => {
    return (
        <div className="col-lg-8 col-md-6">
            <div className="row">
                {
                    cardsFormHome.map((card,key) => {
                        return(
                            <div key={key} className={ "col-lg-4 col-md-12 col-sm-6 wow " + card.fade } data-wow-duration="2s" data-wow-delay="0.3s">
                                <div className="dlab-box-bg m-b30 box-hover style3" style={{ backgroundImage: 'url(' + card.pathImg + ')' }}>
                                    <div className="icon-bx-wraper center p-lr20 p-tb30">
                                        <div className="text-primary m-b20">
                                            <span className="icon-cell icon-md"><i className={ card.icon }></i></span>
                                        </div>
                                        <div className="icon-content">
                                            <h5 className="dlab-tilte">{ card.title }</h5>
                                            <p>{ card.description }</p>
                                        </div>
                                    </div>
                                    <div className="icon-box-btn text-center">
                                        <Link to={card.path} >
                                            <button onClick={ ScrollTopFunction } className="site-button btn-block">
                                            Read More
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Cards
