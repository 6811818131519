import React from 'react'
import { Link } from 'react-router-dom'
import ScrollTopFunction from '../common/ScrollTopFunction'

const GallerySection = () => {
    return (
        <div className="section-full" id="choose-us">
            <div className="row m-lr0">
                <div className="col-xl-6 col-lg-12 p-lr0 d-flex dis-tbl latest-project-info style1 bg-secondry wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="align-self-center text-white">
                        <div className="section-head text-white">
                            <h2 className="title">Why choose us</h2>
                            <p>We are a full-service electrical contractor providing construction services for commercial & industrial clients throughout North and South Carolina</p>
                        </div>
                        <ul className="list-check white list-2 rounded border">
                            <li>
                                <h4 className="m-b10">Services for industrial facilities</h4>
                                <p>We perform both design-build and bid/spec electrical contracting services for the industrial and commercial markets.</p>
                            </li>
                            <li>
                                <h4 className="m-b10">What makes us different </h4>
                                <p>With the years SV Electrical Contractors has grown thanks to our excellent and professional performance in every projects.</p>
                            </li>
                            <li>
                                <h4 className="m-b10">Qualified team</h4>
                                <p>Our employees are highly skilled crafts persons who are licensed electricians with a diversified range of abilities.</p>
                            </li>
                            <li>
                                <h4 className="m-b10">Electrical safety</h4>
                                <p>If you have recently gotten a new system, we recommend scheduling an inspection to ensure that your electrical system is up to requirement.</p>
                            </li>
                            {/* <li>
                                <h4 className="m-b10">Security</h4>
                                <p className="text-justify">Ensuring the safety of our associates is a vital aspect, SV electrical Contractor has a strong commitment to safety is built into the structure of our organization, we believe in building trust through our performance. </p>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <div className="col-xl-6 col-lg-12 p-lr0 wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.3s">
                    <div className="row spno h-100">
                        <div className="col-lg-6 col-md-6 col-sm-6 bg-primary align-items-center d-flex">
                            <div className="dlab-services-box text-white">
                            <h3 className="title text-white m-b15">Commercial</h3>
                            <p className="text-justify">We provide contractors for power and lighting design, data networking and more.</p>
                            <Link to="/comercial-electrical" onClick={ ScrollTopFunction } className="site-button btnhover19 outline white outline-2">Read more</Link>
                                {/* <h2 className="service-year">15<small>years</small></h2> */}
                                {/* <h3 className="title m-b0">Performance</h3> */}
                                {/* <h3 className="title m-b0"><small>Facility,</small><small> Safety</small></h3> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <img className="img-cover" style={{"width":"100%"}} src="images/projects/choose_us_1.jpg" alt=""/>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="video-bx radius-no h100">
                                <img src="images/projects/choose_us_2.jpg" style={{"width":"100%"}} alt="Signature" className="img-cover"/>
                                {/* <div className="video-play-icon">
                                    <a href="https://www.youtube.com/watch?v=_FRZVScwggM" className="popup-youtube video bg-primary"><i className="fa fa-play"></i></a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 bg-secondry align-items-center d-flex">
                            <div className="dlab-services-box text-white">
                                <h3 className="title text-white m-b15">Industrial</h3>
                                <p className="text-justify">We are providing expert execution of design build solutions for businesses.</p>
                                <Link to="/industrial" onClick={ ScrollTopFunction } className="site-button btnhover19 outline white outline-2">Read more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GallerySection
